import React from 'react';
import ReactLoading from 'react-loading';
import {css} from 'glamor';

export interface ILoadingIndicatorProps {
  isLoading?: boolean;
  color?: 'primary' | string;
  size?: 'sm' | 'md' | 'lg' | number;
  position?: {};
}

const LoadingIndicator: React.FC<ILoadingIndicatorProps> = (props: ILoadingIndicatorProps) => {
  const color = props.color || 'hsl(var(--primary))';
  let size = '64px';

  switch (props.size) {
    case 'sm':
      size = '16px';
      break;
    case 'md':
      size = '32px';
      break;
    default:
      size = typeof props.size === 'number' ? `${props.size}px` : '64px';
      break;
  }

  const positioningObject = props.position || {top: '50%', left: '50%', transform: 'translate(-50%, -50%)'};

  const position = css(positioningObject);

  const spinner = props.isLoading ? (
    <ReactLoading
      width={size}
      height={size}
      type="spin"
      color={color}
      className={`absolute z-10 ${position}`}
      data-autoid="loading-indicator"
    ></ReactLoading>
  ) : (
    <></>
  );

  return spinner;
};

export default LoadingIndicator;
