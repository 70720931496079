import React, {useState, useEffect} from 'react';
import {Box, makeStyles, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from '@material-ui/core';
import useAccountAPI from '../../../services/useAccountAPI';
import {IAccounts} from '../../../model/accounts/Accounts';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import {Toast} from '../../../utils/Toast';
import LoadingOverLay from '../../ui/LoadingOverlay';
import DeleteIcon from '@material-ui/icons/Delete';
import {APFooter} from './../APFooter';
import {IMasterAdaptor} from '../../../model/MasterAdaptor';
import {DatTypes} from '../../../model/constants/Constants';
import {ArRefreshBtn} from '../../arRefresh/ArRefresh';

interface IClearingAccount {
  paymentMethod: string;
  clearsTo: string;
}

interface IClearingAccountProps {
  onNextClick: () => void;
  onSkipClick: () => void;
  isAPOnboardingV1?: boolean;
  selectedAdaptor?: IMasterAdaptor;
}

const paymentMethods = [
  {key: 'Mastercard', value: 'Mastercard'},
  {key: 'Visa', value: 'Visa'},
  {key: 'BankTransfer', value: 'BankTransfer'},
  {key: 'AirPlus', value: 'AirPlus'},
];

export const ClearingAccount: React.FunctionComponent<IClearingAccountProps> = ({
  onNextClick,
  isAPOnboardingV1,
  onSkipClick,
}: IClearingAccountProps) => {
  const useClearingAccountStyles = makeStyles({
    paymentsTable: {
      '& table': {
        '& th': {
          color: '#999999',
          fontWeight: 600,
          fontSize: '14px',
          fontFamily: 'Poppins',
          width: '180px',
        },
      },
    },
    dropdown: {
      '&.MuiTableCell-body': {
        padding: '0px!important',
        '& .MuiOutlinedInput-root': {
          borderRadius: '0px',
          background: 'transparent',
          padding: '0px 10px',
          '& input': {
            fontFamily: 'poppins',
            fontSize: '14px',
            textAlign: 'right',
          },
        },
      },
      '& .MuiInputBase-root': {
        maxWidth: '600px',
        width: '100%',
        height: '40px',
        border: '1px solid transparent',
        '&.Mui-focused': {
          border: '1px solid hsl(var(--primary))',
        },
        '&:before, &:after': {
          display: 'none',
        },
      },
      '& .MuiSelect-select': {
        color: '#333333',
        fontSize: '14px',
        lineHeight: '16px',
        fontWeight: '500',
        fontFamily: 'poppins',
        paddingLeft: '10px',
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        '& p': {
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        },
      },
      '& svg': {
        color: 'hsl(var(--primary))',
        width: '25px',
        height: '25px',
      },
      '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
        {
          border: '1px solid #333',
        },
      '& legend': {
        width: '89px',
      },
    },
    '& .menuItem': {
      overflow: 'visible!important',
      '& li': {
        color: '#333',
        fontSize: '14px',
        fontWeight: 600,
        fontFamily: 'poppins',
        textTransform: 'capitalize',
        justifyContent: 'flex-start!important',
        padding: '10px',
        '&:hover': {
          background: 'hsla(var(--primary)/15%)',
        },
        '&.Mui-selected': {
          background: 'hsl(var(--primary)/15%)',
        },
      },
    },
  });

  const classes = useClearingAccountStyles();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const {getAccounts, submitPayment, getPaymentMethods} = useAccountAPI();
  const [clearsToAccounts, setClearsToAccounts] = useState<IAccounts[]>([]);
  const [payments, setPayments] = useState<IClearingAccount[]>([]);

  const getChartAccounts = () => {
    getAccounts().then((data: IAccounts[]) => {
      setClearsToAccounts(data);
    });
  };

  useEffect(() => {
    if (clearsToAccounts && clearsToAccounts.length === 0) {
      getChartAccounts();
    }
    getPaymentMethods().then(data => {
      if (Array.isArray(data?.Settings) && data.Settings.length) {
        const _payments = data.Settings.map((method: any) => ({
          clearsTo: method?.Account?.ID,
          paymentMethod: method?.PaymentMethodName,
        }));
        setPayments(_payments);
      } else {
        if (!payments.length) setPayments([{paymentMethod: '', clearsTo: ''}]);
      }
    });
  }, []);

  const handlePaymentsChange = (event: any, index: number) => {
    let value = event.target.value
      .toString()
      .split('.')
      .map((el: string, i: number) => (i ? el.split('').slice(0, 2).join('') : el))
      .join('.');

    if (event.target.name === 'apPaymentMethod' && payments.find(payment => payment.paymentMethod === value)) {
      Toast.error('Payment Method is already selected.');
      return;
    }

    const targetName = event.target.name === 'apPaymentMethod' ? 'paymentMethod' : 'clearsTo';
    const _tempPayments: Array<any> = [...payments];
    _tempPayments[index][targetName] = value;
    setPayments(_tempPayments);
  };

  const addNewPayment = () => {
    setPayments(prevPayments => [
      ...prevPayments,
      {
        paymentMethod: '',
        clearsTo: '',
      },
    ]);
  };

  const deletePayment = (index: number) => {
    const _payments = [...payments];
    _payments.splice(index, 1);
    setPayments(_payments);
  };

  const onSubmitPayment = async () => {
    if (payments.find(payment => !payment.paymentMethod || !payment.clearsTo)) {
      Toast.error('Missing Payment method/Clears to Account');
      return;
    }
    setIsLoading(true);
    const paymentsData = {
      Settings: payments.map(payment => {
        return {
          PaymentMethodName: payment.paymentMethod.replace(/ /g, ''),
          Account: {
            ID: payment.clearsTo,
            IsActive: Boolean(
              (clearsToAccounts.find(clearsToAccount => clearsToAccount.ID === Number(payment.clearsTo)) || {})
                .IsActive,
            ),
          },
        };
      }),
    };
    submitPayment(paymentsData)
      .then(() => {
        Toast.info('Saved Successfully.');
        onNextClick && onNextClick();
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
        alert('Failed to submit payment');
      });
    onNextClick();
  };

  return (
    <div className={`wrapper relative h-full max-w-full`}>
      <LoadingOverLay isLoading={isLoading} overlayBgColor={'rgba(0, 0, 0, 0.7)'} isAPOnboardingV1={isAPOnboardingV1}>
        <div className={`mx-auto max-w-[795px] text-left`}>
          <Box>
            <h2 className={`mb-1 w-full text-[24px] font-light text-[#121212]`}>Let’s set up your clearing accounts</h2>
            <p className="text-sm font-normal text-spenda-labeltext">Add your clearing accounts</p>
          </Box>
          <div className="mr-4 mt-5 flex flex-col items-end justify-end" style={{marginLeft: 'auto'}}>
            <p style={{fontSize: 'small'}} className="mb-1 font-poppins">
              <ArRefreshBtn
                handleRefreshData={getChartAccounts}
                datTypeId={DatTypes.Accounts}
                hideAdapterImage={true}
                title={'Refresh Charts of Accounts'}
              />
            </p>
          </div>
          <TableContainer className={`${classes.paymentsTable} mt-5 overflow-y-auto`}>
            <Table
              data-autoid="tblPaymentMethods"
              className="tableCellHeight overflow-hidden rounded-[8px_8px_2px_2px]"
              aria-label="simple table"
            >
              <TableHead>
                <TableRow>
                  <TableCell align="left" style={{width: '43%'}}>
                    Payment methods
                  </TableCell>
                  <TableCell align="left" style={{width: '43%'}}>
                    Clears to
                  </TableCell>
                  <TableCell align="left" style={{width: '14%'}}>
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {payments &&
                  payments.map((item: any, index: number) => {
                    return (
                      <TableRow key={index}>
                        <TableCell align="left" className={classes.dropdown}>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            displayEmpty
                            value={item.paymentMethod}
                            style={{
                              color: 'black',
                            }}
                            onChange={event => handlePaymentsChange(event, index)}
                            inputProps={{
                              id: 'deviceSource-native-select',
                              name: 'apPaymentMethod',
                            }}
                            MenuProps={{
                              getContentAnchorEl: null,
                              anchorOrigin: {
                                vertical: 'bottom',
                                horizontal: 'left',
                              },
                            }}
                            data-autoid={`selectPaymentMethod-${index}`}
                          >
                            <MenuItem value="" disabled>
                              <p className="menuItem opacity-50">Select method</p>
                            </MenuItem>
                            {paymentMethods.map(method => {
                              return (
                                <MenuItem value={method.key} key={method.key}>
                                  <p className="menuItem">{method.value}</p>
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </TableCell>
                        <TableCell align="left" className={classes.dropdown}>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            displayEmpty
                            value={item.clearsTo}
                            style={{
                              color: 'black',
                            }}
                            onChange={event => handlePaymentsChange(event, index)}
                            inputProps={{
                              id: 'deviceSource-native-select',
                              'data-autoid': `ddlClearsTo-${index}`,
                              name: 'apClearsTo',
                            }}
                            MenuProps={{
                              getContentAnchorEl: null,
                              anchorOrigin: {
                                vertical: 'bottom',
                                horizontal: 'left',
                              },
                            }}
                            data-autoid={`selectClearTo-${index}`}
                          >
                            <MenuItem value="" disabled>
                              <p className="menuItem opacity-50">Select account</p>
                            </MenuItem>
                            {clearsToAccounts.map(({Name, ID, Code}) => (
                              <MenuItem value={ID} key={ID}>
                                <p className="menuItem">
                                  {Code} - {Name}
                                </p>
                              </MenuItem>
                            ))}
                          </Select>
                        </TableCell>
                        <TableCell align="left">
                          {index !== 0 && (
                            <button onClick={() => deletePayment(index)} data-autoid={`btnDelete-${index}`}>
                              <DeleteIcon className="deleteBtn" htmlColor="#CCCCCC" fontSize="small" />
                            </button>
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          {payments.length < 3 && (
            <span
              className="mt-3 inline-block cursor-pointer font-poppins text-base font-semibold text-primary"
              onClick={() => {
                addNewPayment();
              }}
              data-autoid="btnAddNewPaymentMethod"
            >
              + Add new payment method
            </span>
          )}

          <APFooter
            onNextClick={onSubmitPayment}
            buttonLabel={'Next'}
            isCancelButton={true}
            secondaryButtonLabel="Skip"
            handleCancelClick={onSkipClick}
            isAPOnboardingV1={isAPOnboardingV1}
          />
        </div>
      </LoadingOverLay>
    </div>
  );
};
