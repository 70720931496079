import React, {useState} from 'react';
import {Calendar, Chip} from 'spenda-ui-react';
import {currentActiveView} from 'spenda-ui-react/types/components/calendar';
import moment from 'moment';

import {BookingDiaryList} from '../../components/bookingDiary/BookingDiaryList';
import {BookingDiaryHeader} from '../../components/bookingDiary/BookingDiaryHeader';
import {BookingDiaryScheduler} from '../../components/bookingDiary/BookingDiaryScheduler';
import {CreateServiceJobModal} from '../../components/dialog/CreateServiceJobModal';
import {IServiceJob, ServiceJobScope} from '../../model/service-management/serviceJob';
import {ServiceContextProvider} from '../../context/serviceManagement/ServiceContextProvider';
import useServiceJobAPI from '../../services/useServiceJobAPI';
import {ICustomer} from '../../model/customer/Customer';
import {ISchedulerDialogState} from '../../components/dialog/SchedulerDialog';
import {Actions} from '../../model/constants/Constants';
import {BookingDiaryWeekView} from '../../components/bookingDiary/BookingDiaryWeekView';
import {ServiceDashboardContextProvider} from './context/ServiceDashboardContextProvider';
import {useServiceDashboardContext} from './context/ServiceDashboardContext';
import {LoadingOverlayV1} from '../../components/ui/LoadingOverlayV1';

export type RightPanelView = 'ListView' | 'RequiresAttentionView';

export const ServiceDashboard = () => {
  return (
    <ServiceDashboardContextProvider>
      <ServiceDashboardMainPanel />
    </ServiceDashboardContextProvider>
  );
};

const ServiceDashboardMainPanel = () => {
  // states
  const [serviceJobId, setServiceJobId] = useState<number>();
  const [isCollapsibleView, setIsCollapsibleView] = useState<boolean>(false);
  const [openBookingDialog, setOpenBookingDialog] = useState<boolean>(false);
  const [activeRightPanelView, setActiveRightPanelView] = useState<RightPanelView>('ListView');

  const {createAdhocJob} = useServiceJobAPI();

  const {
    rightPanelLoading,
    isLoading,
    activeView,
    setActiveView,
    calendarEvents,
    selectedDate,
    setSelectedDate,
    technicianList,
    selectedTechnicians,
    scheduledJobs,
    handleReFetchData,
    isShowUnassignedServiceJobs,
    fetchBookingDiaryMonthCalendar,
    fetchBookingDiaryScheduledJobs,
    setIsShowUnassignedServiceJobs,
    setSelectedTechnicians,
  } = useServiceDashboardContext();

  const onDateSelect = async (date: Date) => {
    setSelectedDate(date);
    if (activeView === 'calendar') {
      setActiveView('scheduler');
    }
    fetchBookingDiaryScheduledJobs(date, date, selectedTechnicians);
  };

  const EventView = ({event}: {event: (typeof calendarEvents)[0]}) => {
    return <Chip color="warning" size="lg" value={event.title} className="bg-warning/20" />;
  };

  const handleCreateAdhocBooking = async (
    selectedServiceCustomer: ICustomer,
    scheduleJobInfo?: ISchedulerDialogState,
  ) => {
    const payload: Partial<IServiceJob> = {
      customerID: selectedServiceCustomer.ID!,
      customerName: selectedServiceCustomer.IsIndividual
        ? selectedServiceCustomer.PrimaryContactFullName
        : selectedServiceCustomer.CompanyName,
    };

    if (scheduleJobInfo) {
      payload.bookings = [
        {
          startDateTime_utc: moment(`${scheduleJobInfo.startDate}`).utc().format('YYYY-MM-DD HH:mm'),
          endDateTime_utc: moment(`${scheduleJobInfo.endDate}`).utc().format('YYYY-MM-DD HH:mm'),
          action: Actions.add,
        },
      ];
      if (scheduleJobInfo.technician.id !== '-1') {
        const name = scheduleJobInfo.technician.name.split(' ');
        payload.technicians = [
          {
            firstName: name[0],
            lastName: name[1],
            technicianUserID: +scheduleJobInfo.technician.id,
            action: Actions.add,
          },
        ];
        payload.primaryTechnicianFirstName = name[0];
        payload.primaryTechnicianLastName = name[1];
        payload.primaryTechnicianUserID = scheduleJobInfo.technician.id;
      }
    }

    const response: IServiceJob = await createAdhocJob(payload);
    setServiceJobId(response.serviceJobID);
    setOpenBookingDialog(true);
  };

  const isActiveView = (currentView: currentActiveView, view: currentActiveView) => {
    if (currentView === view) {
      return true;
    }
    return false;
  };

  const handleServiceJobDialogClose = () => {
    setOpenBookingDialog(false);
    setServiceJobId(undefined);
    handleReFetchData();
  };

  const onSwitchClick = (view: currentActiveView) => {
    if (view === 'scheduler') {
      fetchBookingDiaryScheduledJobs(selectedDate, selectedDate, selectedTechnicians);
    }
    setActiveView(view);
    if (view === 'calendar') {
      setIsCollapsibleView(false);
      setSelectedDate(new Date(selectedDate));
    }
  };

  const onWeekChange = (startDate: Date, endDate: Date) => {
    fetchBookingDiaryScheduledJobs(startDate, endDate, selectedTechnicians);
  };
  const onMonthChange = (startDate: Date, endDate: Date) => {
    fetchBookingDiaryMonthCalendar(startDate, endDate, selectedTechnicians);
  };

  const handleEditJob = (id: number) => {
    setServiceJobId(id);
    setOpenBookingDialog(true);
  };

  const onClickDate = (date: Date) => {
    setActiveView('scheduler');
    setSelectedDate(date);
  };

  const onClickDateWithTechnician = (technicianID: number, date: Date) => {
    if (technicianID === -1) {
      setSelectedTechnicians([]);
    } else {
      setIsShowUnassignedServiceJobs(false);
      setSelectedTechnicians([technicianID]);
    }
    setSelectedDate(date);
    setActiveView('scheduler');
  };

  return (
    <div className="relative h-full overflow-hidden bg-[rgba(211,229,239,0.5)]">
      <BookingDiaryHeader handleCreateAdhocBooking={customer => handleCreateAdhocBooking(customer)} />
      <div className="mt-2 flex w-full flex-row px-2">
        <div
          className={`${isCollapsibleView ? '!w-full' : '!w-[calc(100%-443px)]'} relative overflow-y-hidden rounded-md bg-white p-2`}
        >
          <LoadingOverlayV1 isLoading={isLoading}>
            {isActiveView(activeView, 'calendar') && (
              <Calendar
                activeMonth={selectedDate}
                onDateSelect={onDateSelect}
                events={calendarEvents as []}
                eventViewComponent={EventView}
                isShowSwitcher={true}
                currentActiveView={activeView}
                onSwitchClick={onSwitchClick}
                rootContainerProps={{className: 'font-poppins'}}
                containerProps={{className: 'overflow-y-auto h-[calc(100vh-259px)] font-poppins'}}
                onMonthChange={onMonthChange}
              />
            )}
            {isActiveView(activeView, 'scheduler') && (
              <BookingDiaryScheduler
                currentActiveView={activeView}
                selectedDate={selectedDate}
                setSelectedDate={onDateSelect}
                onSwitchClick={onSwitchClick}
                onEditJob={handleEditJob}
                handleCollapse={() => {
                  setIsCollapsibleView(false);
                  setSelectedDate(new Date(selectedDate));
                }}
                isCollapseView={isCollapsibleView}
                selectedTechnicianIDs={selectedTechnicians}
                technicians={technicianList}
                schedulerData={scheduledJobs}
                handleCreateAdhocBooking={handleCreateAdhocBooking}
                isShowUnassignedServiceJobs={isShowUnassignedServiceJobs}
              />
            )}
            {isActiveView(activeView, 'weekCalendar') && (
              <BookingDiaryWeekView
                onWeekChange={onWeekChange}
                schedulerData={scheduledJobs}
                selectedTechnicianIDs={selectedTechnicians}
                technicians={technicianList}
                onSwitchClick={onSwitchClick}
                isShowUnassignedServiceJobs={isShowUnassignedServiceJobs}
                selectedDate={selectedDate}
                onEditJob={handleEditJob}
                onClickDate={onClickDate}
                onClickDateWithTechnician={onClickDateWithTechnician}
              />
            )}
          </LoadingOverlayV1>
        </div>
        <div className={`${isCollapsibleView ? 'hidden' : 'block'} relative ml-2 w-[435px] rounded-md bg-white`}>
          <LoadingOverlayV1 isLoading={rightPanelLoading}>
            <BookingDiaryList
              activeRightPanelView={activeRightPanelView}
              setActiveRightPanelView={setActiveRightPanelView}
              activeLeftPanelView={activeView}
              handleCollapse={() => {
                setSelectedDate(new Date(selectedDate));
                setIsCollapsibleView(true);
              }}
              setOpenBookingDialog={setOpenBookingDialog}
              setServiceJobId={setServiceJobId}
              selectedDate={selectedDate}
            />
          </LoadingOverlayV1>
        </div>
      </div>
      {openBookingDialog && (
        <ServiceContextProvider>
          <CreateServiceJobModal
            serviceJobId={serviceJobId}
            open={openBookingDialog}
            handleClose={handleServiceJobDialogClose}
            scope={serviceJobId ? ServiceJobScope.QuoteDetails : ServiceJobScope.adhocJob}
          />
        </ServiceContextProvider>
      )}
    </div>
  );
};
