import React from 'react';

export function IconDocument(props: SVGProps) {
    return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={11}
          height={12}
          fill="none"
          {...props}
        >
          <path
            className='fill-primary'
            fillRule="evenodd"
            d="M8.46 7a.5.5 0 01-.5.5h-4a.5.5 0 110-1h4a.5.5 0 01.5.5zm-2 1.5h-2.5a.5.5 0 000 1h2.5a.5.5 0 100-1zm4.5-3.257V9.5a2.503 2.503 0 01-2.5 2.5h-5a2.503 2.503 0 01-2.5-2.5v-7A2.503 2.503 0 013.46 0h2.258a3.479 3.479 0 012.475 1.025l1.742 1.743a3.475 3.475 0 011.025 2.475zM7.485 1.732a2.505 2.505 0 00-.525-.39V3.5a.5.5 0 00.5.5h2.158a2.492 2.492 0 00-.39-.525L7.486 1.732zm2.475 3.51c0-.082-.016-.161-.024-.242H7.46a1.5 1.5 0 01-1.5-1.5V1.024C5.879 1.016 5.8 1 5.718 1H3.46a1.5 1.5 0 00-1.5 1.5v7a1.5 1.5 0 001.5 1.5h5a1.5 1.5 0 001.5-1.5V5.242z"
            clipRule="evenodd"
          />
        </svg>
      )
}
