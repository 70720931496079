import React, {createContext, useContext} from 'react';
import {currentActiveView} from 'spenda-ui-react/types/components/calendar';
import {
  ICalendarEvents,
  IRequiresAttentionServiceJobRes,
  IServiceJob,
  ITechnician,
  ITechnicianViewJobs,
} from '../../../model/service-management/serviceJob';

export interface ServiceDashboardContextType {
  searchString: string | undefined;
  selectedDate: Date;
  activeView: currentActiveView;
  serviceJobList: IServiceJob[];
  technicianList: ITechnician[];
  calendarEvents: ICalendarEvents[];
  selectedTechnicians: number[];
  isShowUnassignedServiceJobs: boolean;
  rightPanelLoading: boolean;
  isLoading: boolean;
  scheduledJobs: ITechnicianViewJobs[];
  setSearchString: React.Dispatch<React.SetStateAction<string | undefined>>;
  setActiveView: React.Dispatch<React.SetStateAction<currentActiveView>>;
  requiresAttentionList: IRequiresAttentionServiceJobRes;
  setSelectedDate: React.Dispatch<React.SetStateAction<Date>>;
  setSelectedTechnicians: React.Dispatch<React.SetStateAction<number[]>>;
  setIsShowUnassignedServiceJobs: React.Dispatch<React.SetStateAction<boolean>>;
  fetchBookingDiaryMonthCalendar: (startDate: Date, endDate: Date, technicians?: number[]) => Promise<void>;
  fetchBookingDiaryScheduledJobs: (startDate: Date, endDate: Date, technicians?: number[]) => Promise<void>;
  fetchBookingDiary: () => Promise<void>;
  handleReFetchData: () => void;
  fetchTechnicians: () => Promise<void>;
}

const ServiceDashboardContext = createContext<ServiceDashboardContextType | null>(null);

const useServiceDashboardContext = () => {
  const context = useContext(ServiceDashboardContext);
  if (!context) {
    throw new Error('useServiceDashboardContext must be used within a ServiceDashboardContextProvider');
  }
  return context;
};

export {ServiceDashboardContext, useServiceDashboardContext};
