import React, {SetStateAction, useMemo} from 'react';
import {Checkbox, Menu, MenuHandler, MenuList} from 'spenda-ui-react';

import IconStatusFilter from '../../assets/svg/IconStatusFilter';
import {ITechnician} from '../../model/service-management/serviceJob';
import _ from 'lodash';

interface FilterTechnicianProps {
  technicians?: ITechnician[];
  setSelectedTechnicians: (technicians: number[]) => void;
  selectedTechnicians: number[];
  isShowUnassignedServiceJobs: boolean;
  setIsShowUnassignedServiceJobs: React.Dispatch<SetStateAction<boolean>>;
}

export default function FilterTechnician({
  technicians,
  setSelectedTechnicians,
  selectedTechnicians,
  isShowUnassignedServiceJobs,
  setIsShowUnassignedServiceJobs,
}: FilterTechnicianProps) {
  const technicianList = useMemo(() => {
    if (technicians?.findIndex(tech => tech.userID === -1) === -1) {
      return [
        {
          firstName: 'Unassigned',
          lastName: '',
          displayName: 'Unassigned',
          isEnabledForService: true,
          isServiceTech: true,
          userID: -1,
          userName: 'Unassigned',
        },
        ...technicians,
      ];
    } else if (technicians) {
      return technicians;
    }
    return [];
  }, [technicians]);

  return (
    <Menu
      placement="bottom-end"
      dismiss={{
        itemPress: false,
      }}
    >
      <MenuHandler>
        <div
          className={
            'flex h-10 w-[40px] cursor-pointer items-center rounded-md border !border-spenda-sBlue pl-2 text-xl font-medium'
          }
          data-autoid="ddlStatus"
        >
          <IconStatusFilter data-autoid={`imgStatus`} />
        </div>
      </MenuHandler>
      <MenuList className="z-[99999999] flex flex-col border border-primary p-0">
        {technicianList?.map(tech => {
          const isSelected =
            tech.userID === -1 ? isShowUnassignedServiceJobs : selectedTechnicians.includes(tech.userID!);
          return (
            <div
              key={tech.userID + 'filter'}
              className="flex items-center gap-x-2 px-2.5 py-0 outline-none first:pt-1 last:pb-1 hover:!bg-primary/10 active:!bg-primary/10"
            >
              <Checkbox
                ripple={false}
                color="primary"
                onChange={() => {
                  if (tech.userID === -1) {
                    if (isShowUnassignedServiceJobs && selectedTechnicians.length === 0) {
                      // Prevent unchecking the unassigned user if it's the only checked item
                      return;
                    }
                    setIsShowUnassignedServiceJobs(!isShowUnassignedServiceJobs);
                  } else {
                    if (isSelected && selectedTechnicians.length === 1 && !isShowUnassignedServiceJobs) {
                      // Prevent unchecking the last remaining checked item
                      return;
                    }
                    isSelected
                      ? setSelectedTechnicians(_.without(selectedTechnicians, tech.userID!))
                      : setSelectedTechnicians([...selectedTechnicians, tech.userID!]);
                  }
                }}
                checked={tech.userID === -1 ? isShowUnassignedServiceJobs : isSelected}
                containerProps={{className: 'p-0'}}
                className="border-primary bg-white before:h-5 before:w-5 checked:border-none checked:bg-primary hover:before:opacity-0"
                label={`${tech.firstName} ${tech.lastName}`}
                labelProps={{className: 'px-3 py-2 text-black-800 font-semibold text-base'}}
              />
            </div>
          );
        })}
      </MenuList>
    </Menu>
  );
}
