import React, {useState, PropsWithChildren, useEffect} from 'react';
import {ServiceDashboardContext} from './ServiceDashboardContext';
import {currentActiveView} from 'spenda-ui-react/types/components/calendar';
import {
  IDailySummaryJob,
  IServiceJob,
  ITechnicianViewJobs,
  ServiceJobStatus,
} from '../../../model/service-management/serviceJob';
import {IRequiresAttentionServiceJobRes} from '../../../model/service-management/serviceJob';
import {ITechnician} from '../../../model/service-management/serviceJob';
import {ICalendarEvents} from '../../../model/service-management/serviceJob';
import useServiceJobAPI from '../../../services/useServiceJobAPI';
import moment from 'moment';

function getFilteredStatuses(enumObj: Record<string, string>, exclusions: string[]): string[] {
  // Convert enum values to an array
  const allValues = Object.values(enumObj);
  // Filter out the excluded values
  return allValues.filter(value => !exclusions.includes(value));
}

export const ServiceDashboardContextProvider = (props: PropsWithChildren) => {
  // Define the state variables
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const [searchString, setSearchString] = useState();
  const [activeView, setActiveView] = useState<currentActiveView>('weekCalendar');
  const [jobList, setJobList] = useState<IServiceJob[]>([] as IServiceJob[]);
  const [requiresAttentionList, setRequiresAttentionList] = useState<IRequiresAttentionServiceJobRes>(
    {} as IRequiresAttentionServiceJobRes,
  );
  const [technicianList, setTechnicianList] = useState<ITechnician[]>([] as ITechnician[]);
  const [selectedTechnicians, setSelectedTechnicians] = useState<number[]>([]);
  const [calendarEvents, setCalendarEvents] = useState<ICalendarEvents[]>([] as ICalendarEvents[]);
  const [scheduledJobs, setScheduledJobs] = useState<ITechnicianViewJobs[]>([] as ITechnicianViewJobs[]);
  const [isShowUnassignedServiceJobs, setIsShowUnassignedServiceJobs] = useState<boolean>(true);
  const [rightPanelLoading, setRightPanelLoading] = useState<boolean>(false);

  // Define the Ref to be used in the context
  const startDateRef = React.useRef<Date>(new Date());
  const endDateRef = React.useRef<Date>(new Date());
  const initialSelectedTechnicians = React.useRef(1);

  // Hook to fetch the data from the API
  const {
    getJobsList,
    getRequiresAttentionList,
    getServiceTechnicians,
    getDailySummaryJobs,
    getSchedulerViewJobs,
    isLoading,
  } = useServiceJobAPI();

  const fetchTechnicians = async () => {
    const technicians = await getServiceTechnicians();
    setTechnicianList(technicians.sort((a: ITechnician, b: ITechnician) => a.firstName.localeCompare(b.firstName)));
    setSelectedTechnicians(technicians.map((tech: ITechnician) => tech.userID!));
  };

  const fetchJobsList = async (searchString: string, technicians?: number[]) => {
    return getJobsList({
      StartRow: 1,
      MaxResults: 1000,
      SearchString: searchString,
      SortOrder: 'DESC',
      SortField: 'ModifiedDate',
      IsShowUnassignedServiceJobs: isShowUnassignedServiceJobs,
      Statuses: getFilteredStatuses(ServiceJobStatus, [
        ServiceJobStatus.Completed,
        ServiceJobStatus.Cancelled,
        ServiceJobStatus.Closed,
      ]),
      ServiceTechnicianIDs: technicians,
    });
  };

  const fetchRequiresAttentionList = async (searchString: string, technicians?: number[]) => {
    return getRequiresAttentionList({
      StartRow: 1,
      MaxResults: 1000,
      SearchString: searchString,
      SortOrder: 'DESC',
      SortField: 'ModifiedDate',
      IsShowUnassignedServiceJobs: isShowUnassignedServiceJobs,
      Statuses: getFilteredStatuses(ServiceJobStatus, [
        ServiceJobStatus.Completed,
        ServiceJobStatus.Cancelled,
        ServiceJobStatus.Closed,
      ]),
      ServiceTechnicianIDs: technicians,
    });
  };

  const handleReFetchData = async () => {
    try {
      setRightPanelLoading(true);
      const response = await Promise.all([
        fetchJobsList(searchString ?? '', selectedTechnicians),
        fetchRequiresAttentionList(searchString ?? '', selectedTechnicians),
      ]);
      setJobList(response[0].items);
      setRequiresAttentionList(response[1]);
      await fetchBookingDiary();
    } catch (error) {
      console.error(error);
    } finally {
      setRightPanelLoading(false);
    }
  };

  useEffect(() => {
    // Fetch the data from the API when the component mounts
    // fetch Requires Attention List and jobList and technicianList
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const fetchData = async () => {
      try {
        setRightPanelLoading(true);
        const response = await Promise.all([
          fetchJobsList(searchString ?? '', selectedTechnicians),
          fetchRequiresAttentionList(searchString ?? '', selectedTechnicians),
          getServiceTechnicians(),
        ]);
        setJobList(response[0].items);
        setRequiresAttentionList(response[1]);
        setTechnicianList(response[2].sort((a: ITechnician, b: ITechnician) => a.firstName.localeCompare(b.firstName)));
        setSelectedTechnicians(response[2].map((tech: ITechnician) => tech.userID!));
      } catch (error) {
        console.error(error);
      } finally {
        setRightPanelLoading(false);
      }
    };
    fetchData();
  }, []);

  const handleCalendarEvents = (result: IDailySummaryJob[]) => {
    if (result && result.length) {
      let events = result.map(event => {
        if (!event.countJobs && !event.totalBookedJobHours) return;
        return {
          title: `${event?.countJobs} ${event?.countJobs && event.countJobs > 1 ? 'Jobs' : 'Job'} (${event?.totalBookedJobHours || 0} ${event?.totalBookedJobHours > 1 ? 'hrs' : 'hr'})`,
          date: new Date(event.calendarDate),
        };
      });
      events = events.filter(function (e) {
        return e;
      });
      setCalendarEvents(events as ICalendarEvents[]);
    }
  };
  const fetchBookingDiaryMonthCalendar = async (startDate: Date, endDate: Date, technicians?: number[]) => {
    startDateRef.current = startDate;
    endDateRef.current = endDate;
    const responses = await getDailySummaryJobs({
      StartDate: moment(startDate).format('YYYY-MM-DD'),
      EndDate: moment(endDate).format('YYYY-MM-DD'),
      ServiceTechnicianIDs: technicians,
      IsShowUnassignedServiceJobs: isShowUnassignedServiceJobs,
      SearchString: searchString,
    });
    handleCalendarEvents(responses);
  };

  const fetchBookingDiaryScheduledJobs = async (startDate: Date, endDate: Date, technicians?: number[]) => {
    startDateRef.current = startDate;
    endDateRef.current = endDate;
    const jobs = await getSchedulerViewJobs({
      StartDate: moment(startDate).format('YYYY-MM-DD'),
      EndDate: moment(endDate).format('YYYY-MM-DD'),
      ServiceTechnicianIDs: technicians,
      SearchString: searchString,
      IsShowUnassignedServiceJobs: isShowUnassignedServiceJobs,
    });
    setScheduledJobs(jobs);
  };

  const fetchBookingDiary = async () => {
    if (activeView === 'calendar') {
      fetchBookingDiaryMonthCalendar(startDateRef.current, endDateRef.current, selectedTechnicians);
    } else if (activeView === 'weekCalendar') {
      fetchBookingDiaryScheduledJobs(startDateRef.current, endDateRef.current, selectedTechnicians);
    } else if (activeView === 'scheduler') {
      fetchBookingDiaryScheduledJobs(selectedDate, selectedDate, selectedTechnicians);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setRightPanelLoading(true);
        const response = await Promise.all([
          fetchJobsList(searchString ?? '', selectedTechnicians),
          fetchRequiresAttentionList(searchString ?? '', selectedTechnicians),
        ]);
        setJobList(response[0].items);
        setRequiresAttentionList(response[1]);
        await fetchBookingDiary();
      } catch (error) {
        console.error(error);
      } finally {
        setRightPanelLoading(false);
      }
    };
    if (searchString !== undefined) {
      fetchData();
    }
  }, [searchString]);

  useEffect(() => {
    if (initialSelectedTechnicians.current <= 2) {
      initialSelectedTechnicians.current++;
      return;
    }
    handleReFetchData();
  }, [selectedTechnicians, isShowUnassignedServiceJobs]);

  return (
    <ServiceDashboardContext.Provider
      value={{
        activeView: activeView,
        calendarEvents: calendarEvents,
        fetchBookingDiary: fetchBookingDiary,
        fetchBookingDiaryMonthCalendar: fetchBookingDiaryMonthCalendar,
        fetchBookingDiaryScheduledJobs: fetchBookingDiaryScheduledJobs,
        fetchTechnicians: fetchTechnicians,
        handleReFetchData: handleReFetchData,
        isLoading: isLoading,
        isShowUnassignedServiceJobs: isShowUnassignedServiceJobs,
        requiresAttentionList: requiresAttentionList,
        rightPanelLoading: rightPanelLoading,
        scheduledJobs: scheduledJobs,
        searchString: searchString,
        selectedDate: selectedDate,
        selectedTechnicians: selectedTechnicians,
        serviceJobList: jobList,
        setActiveView: setActiveView,
        setIsShowUnassignedServiceJobs: setIsShowUnassignedServiceJobs,
        setSearchString: setSearchString,
        setSelectedDate: setSelectedDate,
        setSelectedTechnicians: setSelectedTechnicians,
        technicianList: technicianList,
      }}
    >
      {props.children}
    </ServiceDashboardContext.Provider>
  );
};
