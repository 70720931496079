import React from 'react';

export const UndoClaimIcon = (props: {width?: string; height?: string; className?: string}) => {
  return (
    <svg
      width={props.width || '16px'}
      height={props.height || '16px'}
      className={props.className || ''}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.37744 8.58333C1.64229 11.7148 4.052 14.2392 7.16773 14.6493C10.2835 15.0594 13.2643 13.2445 14.3304 10.2882C15.3966 7.332 14.2602 4.03228 11.5999 2.35934C8.9396 0.6864 5.47332 1.09174 3.27077 3.33333H5.3521C5.72029 3.33333 6.01877 3.63181 6.01877 4C6.01877 4.36819 5.72029 4.66667 5.3521 4.66667H2.5901C1.90653 4.6663 1.35247 4.11224 1.3521 3.42867V0.666667C1.3521 0.298477 1.65058 0 2.01877 0C2.38696 0 2.68544 0.298477 2.68544 0.666667V2.052C5.44585 -0.41464 9.53914 -0.662103 12.5766 1.45402C15.6141 3.57014 16.8002 7.49562 15.4428 10.9397C14.0853 14.3838 10.5399 16.4444 6.87546 15.9191C3.211 15.3937 0.387278 12.42 0.052104 8.73333C0.0346928 8.54562 0.0970097 8.35924 0.223821 8.21974C0.350633 8.08025 0.530252 8.0005 0.718771 8C1.05574 7.99594 1.34074 8.24834 1.37744 8.58333Z"
        className="fill-primary"
      />
    </svg>
  );
};
