import * as React from 'react';
import {SVGProps} from 'react';

const IconDeleteLMI = (props: SVGProps<SVGSVGElement>) => (
  <svg width={18} height={22} viewBox="0 0 18 22" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.5801 4.28927C17.5801 4.7785 17.1819 5.17511 16.6907 5.17511H1.52961C1.03837 5.17511 0.640137 4.7785 0.640137 4.28927C0.640137 3.80003 1.03837 3.40342 1.52961 3.40342H4.28697C4.87088 3.40499 5.3607 2.96507 5.41927 2.38647C5.55527 1.02915 6.70381 -0.00353958 8.07346 9.11826e-06H10.1459C11.5156 -0.00353958 12.6641 1.02915 12.8001 2.38647C12.8587 2.96507 13.3485 3.40499 13.9324 3.40342H16.6898C16.9257 3.40342 17.1519 3.49675 17.3187 3.66288C17.4855 3.82901 17.5792 4.05433 17.5792 4.28927H17.5801ZM16.4034 7.85833L16.0476 18.5832C15.9846 20.493 14.4083 22.0067 12.4897 21.9999H5.7297C3.81244 22.0068 2.2367 20.4951 2.17181 18.5868L1.81602 7.85833C1.79981 7.36909 2.1849 6.9594 2.67614 6.94325C3.16738 6.92711 3.57875 7.31063 3.59496 7.79987L3.95075 18.5274C3.98757 19.4792 4.77335 20.2316 5.7297 20.2309H12.4897C13.4474 20.2316 14.2337 19.477 14.2686 18.5239L14.6244 7.79987C14.6406 7.31063 15.052 6.92711 15.5432 6.94325C16.0345 6.9594 16.4196 7.36909 16.4034 7.85833ZM11.2578 3.40342H6.9634H6.96251C7.07969 3.13749 7.15644 2.85565 7.19021 2.56718C7.23586 2.11486 7.61788 1.77037 8.07435 1.76993H10.1468C10.6033 1.77037 10.9853 2.11486 11.031 2.56718C11.0644 2.8556 11.1409 3.13744 11.2578 3.40342ZM7.85909 16.8248V9.28188C7.85909 8.79264 7.46086 8.39604 6.96962 8.39604C6.47838 8.39604 6.08015 8.79264 6.08015 9.28188V16.8284C6.08015 17.3176 6.47838 17.7142 6.96962 17.7142C7.46086 17.7142 7.85909 17.3176 7.85909 16.8284V16.8248ZM12.141 9.28188V16.8248V16.8284C12.141 17.3176 11.7428 17.7142 11.2515 17.7142C10.7603 17.7142 10.3621 17.3176 10.3621 16.8284V9.28188C10.3621 8.79264 10.7603 8.39604 11.2515 8.39604C11.7428 8.39604 12.141 8.79264 12.141 9.28188Z"
      className="fill-primary"
    />
  </svg>
);

export default IconDeleteLMI;
