import React, {useEffect, useState} from 'react';
import {BookOnCalendarDialogContext} from './BookOnCalendarDialogContext';
import {ICalendarEvents} from '../../../../model/service-management/serviceJob';
import {ITechnicianViewJobs} from '../../../../model/service-management/serviceJob';
import {ITechnician} from '../../../../model/service-management/serviceJob';
import useServiceJobAPI from '../../../../services/useServiceJobAPI';
import {currentActiveView} from 'spenda-ui-react/types/components/calendar';
import {IDailySummaryJob} from '../../../../model/service-management/serviceJob';
import moment from 'moment';

interface BookOnCalendarDialogContextProviderProps extends React.PropsWithChildren {
  alreadySelectedTechnicians: number[];
  technicians: ITechnician[];
}

export const BookOnCalendarDialogContextProvider = ({
  children,
  alreadySelectedTechnicians,
  technicians,
}: BookOnCalendarDialogContextProviderProps) => {
  // states
  const [selectedDate, setSelectedDate] = React.useState<Date>(new Date());
  const [selectedTechnicians, setSelectedTechnicians] = useState<number[]>([]);
  const [calendarEvents, setCalendarEvents] = useState<ICalendarEvents[]>([] as ICalendarEvents[]);
  const [scheduledJobs, setScheduledJobs] = useState<ITechnicianViewJobs[]>([] as ITechnicianViewJobs[]);
  const [isShowUnassignedServiceJobs, setIsShowUnassignedServiceJobs] = useState<boolean>(true);
  const [activeView, setActiveView] = useState<currentActiveView>('weekCalendar');

  //Hooks
  const {isLoading, getDailySummaryJobs, getSchedulerViewJobs} = useServiceJobAPI();

  // ref
  const initialSelectedTechnicians = React.useRef(1);
  const startDateRef = React.useRef<Date>(new Date());
  const endDateRef = React.useRef<Date>(new Date());

  const handleCalendarEvents = (result: IDailySummaryJob[]) => {
    if (result && result.length) {
      let events = result.map(event => {
        if (!event.countJobs && !event.totalBookedJobHours) return;
        return {
          title: `${event?.countJobs} ${event?.countJobs && event.countJobs > 1 ? 'Jobs' : 'Job'} (${event?.totalBookedJobHours || 0} ${event?.totalBookedJobHours > 1 ? 'hrs' : 'hr'})`,
          date: new Date(event.calendarDate),
        };
      });
      events = events.filter(function (e) {
        return e;
      });
      setCalendarEvents(events as ICalendarEvents[]);
    }
  };

  const fetchBookingDiaryMonthCalendar = async (startDate: Date, endDate: Date, technicians?: number[]) => {
    startDateRef.current = startDate;
    endDateRef.current = endDate;
    const responses = await getDailySummaryJobs({
      StartDate: moment(startDate).format('YYYY-MM-DD'),
      EndDate: moment(endDate).format('YYYY-MM-DD'),
      ServiceTechnicianIDs: technicians,
      IsShowUnassignedServiceJobs: isShowUnassignedServiceJobs,
    });
    handleCalendarEvents(responses);
  };

  const fetchBookingDiaryScheduledJobs = async (startDate: Date, endDate: Date, technicians?: number[]) => {
    startDateRef.current = startDate;
    endDateRef.current = endDate;
    const jobs = await getSchedulerViewJobs({
      StartDate: moment(startDate).format('YYYY-MM-DD'),
      EndDate: moment(endDate).format('YYYY-MM-DD'),
      ServiceTechnicianIDs: technicians,
      IsShowUnassignedServiceJobs: isShowUnassignedServiceJobs,
    });
    setScheduledJobs(jobs);
  };

  const fetchBookingDiary = async () => {
    if (activeView === 'calendar') {
      fetchBookingDiaryMonthCalendar(startDateRef.current, endDateRef.current, selectedTechnicians);
    } else if (activeView === 'weekCalendar') {
      fetchBookingDiaryScheduledJobs(startDateRef.current, endDateRef.current, selectedTechnicians);
    } else if (activeView === 'scheduler') {
      fetchBookingDiaryScheduledJobs(selectedDate, selectedDate, selectedTechnicians);
    }
  };

  useEffect(() => {
    setSelectedTechnicians(alreadySelectedTechnicians);
  }, [alreadySelectedTechnicians]);

  useEffect(() => {
    if (initialSelectedTechnicians.current <= 2) {
      initialSelectedTechnicians.current++;
      return;
    }
    fetchBookingDiary();
  }, [selectedTechnicians, isShowUnassignedServiceJobs]);

  return (
    <BookOnCalendarDialogContext.Provider
      value={{
        activeView: activeView,
        calendarEvents: calendarEvents,
        fetchBookingDiary: fetchBookingDiary,
        fetchBookingDiaryMonthCalendar: fetchBookingDiaryMonthCalendar,
        fetchBookingDiaryScheduledJobs: fetchBookingDiaryScheduledJobs,
        isLoading: isLoading,
        isShowUnassignedServiceJobs: isShowUnassignedServiceJobs,
        scheduledJobs: scheduledJobs,
        selectedDate: selectedDate,
        selectedTechnicians: selectedTechnicians,
        setActiveView: setActiveView,
        setIsShowUnassignedServiceJobs: setIsShowUnassignedServiceJobs,
        setSelectedDate: setSelectedDate,
        setSelectedTechnicians: setSelectedTechnicians,
        technicianList: technicians,
      }}
    >
      {children}
    </BookOnCalendarDialogContext.Provider>
  );
};
