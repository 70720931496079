import * as React from 'react';
import {SVGProps} from 'react';

const IconTipBulb = (props: SVGProps<SVGSVGElement>) => (
  <svg width={23} height={29} viewBox="0 0 23 29" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.6043 2.76526C15.5986 0.0800389 11.3543 -0.722646 7.57562 0.679511C3.79694 2.08167 1.10363 5.45869 0.577129 9.4546C0.106246 12.9438 1.3657 16.4435 3.952 18.8325C5.54535 20.2297 6.47983 22.2311 6.52817 24.3497V24.5443C6.53083 27.0057 8.52553 29.0004 10.9869 29.003H11.7361C14.1975 29.0004 16.1922 27.0057 16.1948 24.5443V23.8809C16.2314 22.0066 17.084 20.2415 18.5293 19.0476C20.8724 16.9955 22.2224 14.0367 22.2367 10.9221C22.251 7.80748 20.9283 4.83642 18.6043 2.76285V2.76526ZM11.7361 26.5863H10.9869C9.85966 26.585 8.94617 25.6715 8.94484 24.5443C8.94484 24.5443 8.93638 24.2301 8.93517 24.1697H13.7782V24.5443C13.7768 25.6715 12.8633 26.585 11.7361 26.5863ZM16.9319 17.2326C15.5561 18.4259 14.5626 19.9982 14.0754 21.753H12.5698V13.0723C14.013 12.5621 14.9802 11.2004 14.9865 9.66968C14.9865 9.00234 14.4455 8.46135 13.7782 8.46135C13.1108 8.46135 12.5698 9.00234 12.5698 9.66968C12.5698 10.337 12.0288 10.878 11.3615 10.878C10.6942 10.878 10.1532 10.337 10.1532 9.66968C10.1532 9.00234 9.61218 8.46135 8.94484 8.46135C8.27749 8.46135 7.7365 9.00234 7.7365 9.66968C7.74285 11.2004 8.71 12.5621 10.1532 13.0723V21.753H8.55575C8.01078 19.9516 6.98869 18.3312 5.59775 17.0635C3.18721 14.8173 2.30035 11.3758 3.32513 8.24444C4.34991 5.11304 7.0998 2.86171 10.3719 2.47526C10.7041 2.43818 11.0381 2.41962 11.3724 2.41968C13.4478 2.41351 15.4513 3.17927 16.9935 4.5681C18.8016 6.18034 19.8307 8.49102 19.8196 10.9134C19.8085 13.3359 18.7583 15.637 16.9355 17.2326H16.9319Z"
      className="fill-primary"
    />
  </svg>
);

export default IconTipBulb;
