import React, {useState} from 'react';
import {Scheduler, Typography} from 'spenda-ui-react';
import {currentActiveView} from 'spenda-ui-react/types/components/calendar';
import moment from 'moment';

import {AlertDialog} from './AlertDialogSlideV2';
import {ITechnician, ITechnicianViewJobs} from '../../model/service-management/serviceJob';
import {useScheduleJob} from '../../hooks/useScheduleJob';
import {TemplateViewSchedulerTask} from '../bookingDiary/BookingDiaryScheduler';

interface ISchedulerDialogProps {
  isShowUnassignedServiceJobs?: boolean;
  schedulerData: ITechnicianViewJobs[];
  selectedDate: Date;
  selectedTechnicians: number[];
  technicians: ITechnician[];
  setSelectedDate: (date: Date) => void;
  onSwitchClick: (view: currentActiveView) => void;
  onScheduleJob: (jobInfo: ISchedulerDialogState) => void;
}

export type ISchedulerDialogState = {
  startDate: Date;
  endDate: Date;
  technician: {
    id: string;
    name: string;
  };
};

export default function SchedulerDialog({
  selectedDate,
  setSelectedDate,
  selectedTechnicians,
  isShowUnassignedServiceJobs,
  schedulerData,
  technicians,
  onScheduleJob,
  onSwitchClick,
}: ISchedulerDialogProps) {
  const [selectedJobInfo, setSelectedJobInfo] = useState<ISchedulerDialogState>();
  const [showWarning, setShowWarning] = useState(false);

  const {data, techniciansList} = useScheduleJob({
    schedulerData: schedulerData,
    selectedTechnicianIDs: selectedTechnicians,
    technicians: technicians,
    isShowUnassignedServiceJobs: isShowUnassignedServiceJobs,
  });

  const onDateChange = (date: Date) => {
    setSelectedDate(date);
  };

  const onDragEnd = (technician: (typeof techniciansList)[0], startDateTime: Date, endDateTime: Date) => {
    setSelectedJobInfo({
      startDate: startDateTime,
      endDate: endDateTime,
      technician,
    });
    setShowWarning(true);
  };

  return (
    <>
      <Scheduler
        isShowSwitcher
        workingHours={{
          start: '12AM',
          end: '12AM',
        }}
        currentActiveView="scheduler"
        onSwitchClick={onSwitchClick}
        data={data}
        technician={techniciansList}
        ViewTask={TemplateViewSchedulerTask}
        selectedDate={selectedDate}
        onDateChange={onDateChange}
        onDragEnd={onDragEnd}
        headerProps={{
          className: 'text-black pt-2.5',
        }}
        getTechnicianIndexByEvent={event => {
          return techniciansList.findIndex(tech => tech.id == event.technician.id);
        }}
        ViewGridHeading={props => <span>{props.technician.name}</span>}
        containerProps={{
          className: 'h-[calc(80vh-102px)] rounded-md border-b border-[#E8E8E8]',
        }}
      />
      {showWarning && (
        <AlertDialogWhenScheduling
          onScheduleJob={onScheduleJob}
          selectedJobInfo={selectedJobInfo!}
          onClose={setShowWarning}
        />
      )}
    </>
  );
}

interface AlertDialogWhenSchedulingProps {
  onScheduleJob: (jobInfo: ISchedulerDialogState) => void;
  selectedJobInfo: ISchedulerDialogState;
  onClose: (show: boolean) => void;
}

export const AlertDialogWhenScheduling = ({
  onScheduleJob,
  selectedJobInfo,
  onClose,
}: AlertDialogWhenSchedulingProps) => {
  return (
    <AlertDialog
      data-autoid={'dlgConfirmationforScheduling'}
      title="Confirmation for Scheduling"
      headingTextSize="h2"
      headingClassess="text-black justify-center"
      actions={[
        {
          label: 'Go Back',
          variant: 'outlined',
          action: () => onClose(false),
        },
        {
          label: 'Schedule Booking',
          action: () => onScheduleJob(selectedJobInfo),
        },
      ]}
      dialogBodyClassess="flex justify-center"
      contentClass="w-[400px]"
      body={
        <div className="flex flex-col justify-center">
          <div className="flex flex-row justify-center gap-6 pt-4">
            <div className="flex flex-col gap-1">
              <Typography variant="small" className="font-bold text-black-800" data-autoid="txtDate">
                Date:
              </Typography>
              <Typography variant="small" className="font-bold text-black-800" data-autoid="txtStart">
                Start:
              </Typography>
              <Typography variant="small" className="font-bold text-black-800" data-autoid="txtEnd">
                End:
              </Typography>
              <Typography variant="small" className="font-bold text-black-800" data-autoid="txtDuration">
                Duration:
              </Typography>
            </div>
            <div className="flex flex-col gap-1">
              <Typography
                variant="small"
                className="font-poppins font-normal text-black-800"
                data-autoid="txtJobStartDate"
              >
                {`${moment(selectedJobInfo.startDate).format('Do MMMM YYYY')}`}
              </Typography>
              <Typography
                variant="small"
                className="font-poppins font-normal text-black-800"
                data-autoid="txtJobStartTime"
              >
                {`${moment(selectedJobInfo.startDate).format('h:mm a')}`}
              </Typography>
              <Typography
                variant="small"
                className="font-poppins font-normal text-black-800"
                data-autoid="txtJobEndTime"
              >
                {`${moment(selectedJobInfo.endDate).format('h:mm a')}`}
              </Typography>
              <Typography
                variant="small"
                className="font-poppins font-normal text-black-800"
                data-autoid="txtJobDuration"
              >
                {`${moment(selectedJobInfo.endDate).diff(moment(selectedJobInfo.startDate), 'minutes') / 60} ${moment(selectedJobInfo.endDate).diff(moment(selectedJobInfo.startDate), 'minutes') / 60 > 1 ? 'hrs' : 'hr'}`}
              </Typography>
            </div>
          </div>
          <Typography
            variant="small"
            className="flex w-full max-w-[378px] items-center justify-center py-8 text-center font-normal text-black-800"
          >
            If these details are correct, please click 'Schedule Booking'. Otherwise, to select a different time, please
            select 'Go Back.
          </Typography>
        </div>
      }
    />
  );
};
