import React, {useState} from 'react';
import {WeekCalendar} from 'spenda-ui-react';
import {onSwitchClick} from 'spenda-ui-react/types/components/calendar';
import {IQuoteLinkedTran, ITechnician} from '../../model/service-management/serviceJob';
import {ITechnicianViewJobs} from '../../model/service-management/serviceJob';
import {useScheduleJob} from '../../hooks/useScheduleJob';
import {TemplateWeekViewScheduler} from './TemplateWeekViewScheduler';
import {useHistory} from 'react-router-dom';
import {DatTypes} from '../../model/constants/Constants';
import {APInvoiceDetailView} from '../../screens/purchasing/APInvoiceDetailView';
import {AUTH_SELLING_QUOTES_CREATE, AUTH_SELLING_QUOTES_DETAILS} from '../../routes/QuoteManagementRoutes';

interface BookingDiaryWeekViewProps {
  onSwitchClick: onSwitchClick;
  onWeekChange: (startDate: Date, endDate: Date) => void;
  schedulerData: ITechnicianViewJobs[];
  selectedTechnicianIDs: number[];
  technicians: ITechnician[];
  isShowUnassignedServiceJobs: boolean;
  selectedDate: Date;
  onEditJob?: (serviceJobId: number) => void;
  onClickDate?: (date: Date) => void;
  onClickDateWithTechnician?: (technicianID: number, date: Date) => void;
}

export const BookingDiaryWeekView = ({
  onSwitchClick,
  onWeekChange,
  schedulerData,
  selectedTechnicianIDs,
  isShowUnassignedServiceJobs,
  technicians,
  selectedDate,
  onEditJob,
  onClickDate,
  onClickDateWithTechnician,
}: BookingDiaryWeekViewProps) => {
  const [workingWeek, setWorkingWeek] = useState(false);
  const [invoiceId, setInvoiceId] = useState<number | undefined>(undefined);

  const history = useHistory();

  const {data, techniciansList} = useScheduleJob({
    schedulerData: schedulerData,
    selectedTechnicianIDs: selectedTechnicianIDs,
    technicians: technicians,
    isShowUnassignedServiceJobs: isShowUnassignedServiceJobs,
  });

  const TemplateEventView = ({item}: {item: (typeof data)[0]}) => {
    const onEditJobClick = () => {
      onEditJob?.(item.serviceJobID);
    };

    const onClickOfLinkedTransaction = (transaction: IQuoteLinkedTran) => {
      if (transaction.datTypeID === DatTypes.Invoice) {
        setInvoiceId(transaction.id);
      } else if (transaction?.isSent) {
        history.push(`${AUTH_SELLING_QUOTES_DETAILS}${transaction.id}`);
      } else {
        history.push(`${AUTH_SELLING_QUOTES_CREATE}${transaction.id}`);
      }
    };

    return (
      <TemplateWeekViewScheduler
        key={item?.id}
        job={item}
        onEditJobClick={onEditJobClick}
        onClickOfLinkedTransaction={onClickOfLinkedTransaction}
      />
    );
  };

  const handleDateWithTechnician = (technician: (typeof techniciansList)[0], date: Date) => {
    onClickDateWithTechnician?.(parseInt(technician.id), date);
  };

  return (
    <>
      <WeekCalendar
        selectedDate={selectedDate}
        workingWeek={workingWeek}
        onWorkingWeekChange={value => setWorkingWeek(value)}
        technician={techniciansList}
        data={data}
        getTechnicianIndexByEvent={event => {
          return techniciansList.findIndex(tech => tech.id === event.technician.id);
        }}
        getDatesFromEvent={event => {
          return {
            startDate: new Date(event.startDate),
            endDate: new Date(event.endDate),
          };
        }}
        viewTask={TemplateEventView}
        isShowSwitcher
        currentActiveView="weekCalendar"
        containerProps={{
          className: 'h-[calc(100vh-310px)]',
        }}
        gridProps={{
          className: '[&>h2]:z-[1]',
        }}
        onSwitchClick={onSwitchClick}
        onClickDateWithTechnician={handleDateWithTechnician}
        onDateSelect={onClickDate}
        setDateInterval={onWeekChange}
        viewGridHeading={({technician}) => (
          <span className="flex w-full items-center justify-center">{technician.name}</span>
        )}
      />
      {invoiceId && (
        <APInvoiceDetailView
          open={Boolean(invoiceId)}
          onClose={() => {
            setInvoiceId(undefined);
          }}
          isServiceJobInvoice
          apInvoiceId={{transId: invoiceId!}}
        />
      )}
    </>
  );
};
