import {createContext, useContext} from 'react';
import {ServiceDashboardContextType} from '../../context/ServiceDashboardContext';

interface BookOnCalendarDialogContextProviderProps {
  activeView: ServiceDashboardContextType['activeView'];
  calendarEvents: ServiceDashboardContextType['calendarEvents'];
  fetchBookingDiary: ServiceDashboardContextType['fetchBookingDiary'];
  fetchBookingDiaryMonthCalendar: ServiceDashboardContextType['fetchBookingDiaryMonthCalendar'];
  fetchBookingDiaryScheduledJobs: ServiceDashboardContextType['fetchBookingDiaryScheduledJobs'];
  isLoading: ServiceDashboardContextType['isLoading'];
  isShowUnassignedServiceJobs: ServiceDashboardContextType['isShowUnassignedServiceJobs'];
  scheduledJobs: ServiceDashboardContextType['scheduledJobs'];
  selectedDate: ServiceDashboardContextType['selectedDate'];
  selectedTechnicians: ServiceDashboardContextType['selectedTechnicians'];
  setActiveView: ServiceDashboardContextType['setActiveView'];
  setIsShowUnassignedServiceJobs: ServiceDashboardContextType['setIsShowUnassignedServiceJobs'];
  setSelectedDate: ServiceDashboardContextType['setSelectedDate'];
  setSelectedTechnicians: ServiceDashboardContextType['setSelectedTechnicians'];
  technicianList: ServiceDashboardContextType['technicianList'];
}

export const BookOnCalendarDialogContext = createContext<BookOnCalendarDialogContextProviderProps>(
  {} as BookOnCalendarDialogContextProviderProps,
);

export const useBookOnCalendarDialogContext = () => {
  const context = useContext(BookOnCalendarDialogContext);
  if (context === undefined) {
    throw new Error('useBookOnCalendarDialogContext must be used within a BookOnCalendarDialogContext');
  }
  return context;
};
