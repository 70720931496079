import React, {useCallback, useMemo, useState} from 'react';
import {Button, Chip, SearchInput, Typography} from 'spenda-ui-react';
import {debounce} from 'lodash';

import {Header} from '../layout/Header';
import {SelectQuoteCustomerDialog} from '../dialog/SelectQuoteCustomerDialog';
import {ICustomer} from '../../model/customer/Customer';
import FilterTechnician from './FilterTechnician';
import {useServiceDashboardContext} from '../../screens/service-management/context/ServiceDashboardContext';

interface IBookingDiaryHeader {
  handleCreateAdhocBooking: (selectedServiceCustomer: ICustomer) => void;
}

export const BookingDiaryHeader = (props: IBookingDiaryHeader) => {
  const {handleCreateAdhocBooking} = props;

  const [query, setQuery] = useState<string>('');
  const [showSelectCustomerDialog, setShowSelectCustomerDialog] = useState(false);
  const [selectedServiceCustomer, setSelectedServiceCustomer] = useState<ICustomer>();

  const {
    technicianList,
    setSelectedTechnicians,
    selectedTechnicians,
    setSearchString,
    fetchTechnicians,
    isShowUnassignedServiceJobs,
    setIsShowUnassignedServiceJobs,
  } = useServiceDashboardContext();

  const debouncedSearch = useCallback(
    debounce((value: string) => {
      setSearchString(value);
    }, 300),
    [],
  );

  const handleRefreshTechnician = async () => {
    fetchTechnicians();
  };

  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(e.target.value);
    debouncedSearch(e.target.value);
  };

  const handleCreateBooking = () => {
    setSelectedServiceCustomer(undefined);
    setShowSelectCustomerDialog(true);
  };

  const isFilterApplied = useMemo(() => {
    if (!isShowUnassignedServiceJobs) {
      return true;
    }
    if (selectedTechnicians.length === technicianList.length) {
      return false;
    }
    return true;
  }, [isShowUnassignedServiceJobs, selectedTechnicians, technicianList]);

  const handleResetFilter = () => {
    if (!isShowUnassignedServiceJobs) {
      setIsShowUnassignedServiceJobs(true);
    }
    setSelectedTechnicians(technicianList.map(tech => tech.userID!));
  };

  return (
    <>
      <Header afterUserSettingDialogClose={handleRefreshTechnician}></Header>
      <div className="m-2 flex h-[60px] items-center justify-center rounded-lg bg-white">
        {isFilterApplied ? (
          <div className="flex gap-x-2.5 pl-2">
            <Typography variant="h3">
              Technician
              {selectedTechnicians.length > 1 || (selectedTechnicians.length == 1 && isShowUnassignedServiceJobs)
                ? 's'
                : ''}
              :
            </Typography>
            {selectedTechnicians.map((tech, index) => {
              const technician = technicianList.find(tech => tech.userID === selectedTechnicians[index]);
              return (
                <Chip size="sm" key={technician?.userID} value={`${technician?.firstName} ${technician?.lastName}`} />
              );
            })}
            {isShowUnassignedServiceJobs && <Chip size="sm" value={'Unassigned'} />}
          </div>
        ) : (
          <Typography data-autoid={'txtBookDiary'} className="whitespace-nowrap pl-2 text-xl font-light text-black-800">
            Booking Diary
          </Typography>
        )}
        <div className="flex w-full justify-end gap-x-2 pr-2">
          <div className="w-52">
            <SearchInput
              reverse={true}
              clearIcon
              clearIconProps={{
                onClick: () => {
                  setQuery('');
                  setSearchString('');
                },
              }}
              iconButtonProps={{
                name: 'Search',
              }}
              data-autoid="txtSearchInput"
              autoFocus
              placeholder="Search..."
              onChange={onSearchChange}
              value={query}
            />
          </div>
          <FilterTechnician
            technicians={technicianList}
            setSelectedTechnicians={setSelectedTechnicians}
            selectedTechnicians={selectedTechnicians}
            isShowUnassignedServiceJobs={isShowUnassignedServiceJobs}
            setIsShowUnassignedServiceJobs={setIsShowUnassignedServiceJobs}
          />
          {isFilterApplied && (
            <Button variant="outlined" onClick={handleResetFilter}>
              Reset
            </Button>
          )}
          <Button onClick={handleCreateBooking}>Create Booking</Button>
        </div>
      </div>
      {showSelectCustomerDialog && (
        <SelectQuoteCustomerDialog
          isService={true}
          open={showSelectCustomerDialog}
          handleCancel={() => {
            setShowSelectCustomerDialog(!showSelectCustomerDialog);
            setSelectedServiceCustomer(undefined);
          }}
          handleConfirm={() => {
            setShowSelectCustomerDialog(!showSelectCustomerDialog);
            handleCreateAdhocBooking(selectedServiceCustomer!);
          }}
          setSelectedQuoteCustomer={setSelectedServiceCustomer}
          selectedQuoteCustomer={selectedServiceCustomer}
        />
      )}
    </>
  );
};
