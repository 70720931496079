import React from 'react';
import {SelectableButton} from '../AdvanceFilter';
import {IGetInventoryTemplates, IMarketPlaceInventoryTemplates} from '../../../model/OnboardingV1Step';
import clsx from 'clsx';
import {ISelectedInventoryValue} from '../../lowTouchOnboarding/SetUpInventory';

interface ISelectInventoryButtons {
  inventoryData?: IGetInventoryTemplates;
  selectedTitles: ISelectedInventoryValue[];
  setSelectedTitles: React.Dispatch<React.SetStateAction<ISelectedInventoryValue[]>>;
}

const InventorySelectableButtons = (props: ISelectInventoryButtons) => {
  // Props
  const {selectedTitles, inventoryData, setSelectedTitles} = props;

  // Functions
  const handleClick = (inventoryData: IMarketPlaceInventoryTemplates) => {
    setSelectedTitles(prevTitles => {
      const isAlreadySelected = prevTitles.some(title => title.shortDescription === inventoryData.shortDescription);
      if (isAlreadySelected) {
        if (inventoryData.shortDescription === 'Parts') {
          return prevTitles;
        }
        return prevTitles.filter(title => title.shortDescription !== inventoryData.shortDescription);
      } else {
        return [...prevTitles, inventoryData];
      }
    });
  };

  return (
    <>
      <div className="mb-5 mt-5 grid w-full grid-cols-2 gap-y-5 sm:mt-10 sm:w-[520px] sm:!max-w-[520px] sm:grid-cols-3 sm:gap-x-5">
        {inventoryData?.marketplaceInventoryTemplates?.map((item, index) => {
          const isSelected = selectedTitles.some(title => title.shortDescription === item.shortDescription);
          return (
            <div className="flex w-full justify-center" key={index}>
              <SelectableButton
                label={item.shortDescription}
                isSelected={isSelected}
                onClick={() => handleClick(item)}
                className={clsx(
                  'h-[60px] w-[148px] bg-primary/10 text-center text-sm  text-primary hover:border-primary sm:h-[40px] sm:w-[165px]',
                  {
                    '!border-solid !border-primary !bg-primary-header': isSelected,
                  },
                )}
                dataAutoId={`btnSelectInventoryItem-${item.shortDescription}`}
              />
            </div>
          );
        })}
      </div>
    </>
  );
};

export default InventorySelectableButtons;
