import React from 'react';
import {PropsWithChildren} from 'react';
import LoadingIndicator, {ILoadingIndicatorProps} from './LoadingIndicator';

interface LoadingOverlayProps extends PropsWithChildren, ILoadingIndicatorProps {
  isLoading: boolean;
}

export const LoadingOverlayV1 = ({children, ...rest}: LoadingOverlayProps) => {
  return (
    <>
      <LoadingIndicator
        size="md"
        position={{
          height: '100% !important',
          display: 'flex',
          position: 'absolute',
          left: '0',
          right: '0',
          marginLeft: 'auto',
          marginRight: 'auto',
          zIndex: 50,
        }}
        {...rest}
      />
      {children}
    </>
  );
};
