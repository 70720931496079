import React, {useEffect, useState} from 'react';
import {IconButton, Typography} from 'spenda-ui-react';
import {ARTable} from '../AccountsReceivable/ARTable';
import Delete from '@material-ui/icons/Delete';
import PSBLOnboardingFooter, {ResponsiveBookOnboardingButton} from '../buttons/PSBLOnboardingFooter';
import {SelectCOADropdown} from '../AccountsReceivable/required-attention/SelectCOADropdown';
import {IGetInventoryTemplates, IMarketPlaceInventoryTemplates} from '../../model/OnboardingV1Step';
import {useOnboarding} from '../../services/useOnboarding';
import {usePSBLHook} from '../../hooks/useARHook';
import {defaultMarketPlacePath} from '../../config';
import {ChartsOfAccountType} from '../../model/constants/Constants';
import InventoryItemsSelectionModal from '../AccountsReceivable/modals/InventoryItemsSelectionModal';
import InventorySelectableButtons from '../AccountsReceivable/modals/InventorySelectableButtons';
import {isMobile} from 'react-device-detect';
import IconDeleteLMI from '../../assets/svg/IconDeleteLMI';

enum CurrentScreenEnum {
  SelectableButtonView = 'SelectableButtonView',
  EditableTableView = 'EditableTableView',
}

interface ISetupInventoryProps {
  handleNext: () => void;
}

export const SetUpInventory = (props: ISetupInventoryProps) => {
  // Hooks
  const {getSupplierByMarketplacePath} = usePSBLHook();
  const marketplaceID = getSupplierByMarketplacePath(defaultMarketPlacePath)?.MarketplaceID;

  // Props
  const {handleNext} = props;

  // State
  const [currentState, setcurrentState] = useState<CurrentScreenEnum>(CurrentScreenEnum.SelectableButtonView);
  const [inventoryData, setInventoryData] = useState<IGetInventoryTemplates>();
  const [selectedTitles, setSelectedTitles] = useState<IMarketPlaceInventoryTemplates[]>([]);

  useEffect(() => {
    const defaultSelected = (inventoryData?.marketplaceInventoryTemplates || []).filter(
      item => item.shortDescription === 'Parts',
    );
    setSelectedTitles(prev => [...prev, ...defaultSelected]);
  }, [inventoryData]);

  // API
  const {getInventoryTemplate} = useOnboarding();

  useEffect(() => {
    const fetchData = async () => {
      if (marketplaceID) {
        await getSetupInventory(marketplaceID);
      }
    };
    fetchData();
  }, [marketplaceID]);

  // Function
  const getSetupInventory = async (marketplaceID: number) => {
    try {
      const response = await getInventoryTemplate(marketplaceID);
      setInventoryData(response?.value);
    } catch {}
  };

  return (
    <div className="wrapper relative h-full w-full overflow-hidden bg-white">
      {currentState === 'SelectableButtonView' ? (
        <SelectableButtonView
          onScreenChange={setcurrentState}
          inventoryData={inventoryData}
          selectedTitles={selectedTitles}
          setSelectedTitles={setSelectedTitles}
        />
      ) : (
        <EditableTableView
          handleNext={handleNext}
          setSelectedTitles={setSelectedTitles}
          selectedTitles={selectedTitles}
          inventoryData={inventoryData}
        />
      )}
    </div>
  );
};

interface ISelectableButtonViewProps {
  onScreenChange: (currentScreen: CurrentScreenEnum) => void;
  inventoryData?: IGetInventoryTemplates;
  selectedTitles: IMarketPlaceInventoryTemplates[];
  setSelectedTitles: React.Dispatch<React.SetStateAction<IMarketPlaceInventoryTemplates[]>>;
}

const SelectableButtonView = (props: ISelectableButtonViewProps) => {
  //Props
  const {inventoryData, onScreenChange, selectedTitles, setSelectedTitles} = props;

  return (
    <div className="wrapper relative h-full w-full overflow-hidden bg-white pb-24">
      <div className={`mx-auto flex h-full w-full max-w-[622px] flex-col justify-between overflow-y-auto`}>
        <div>
          <p className={`text-left font-poppins text-[20px] font-medium text-[#333] sm:text-2xl sm:font-light`}>
            What do you use your Capricorn trade account for?
          </p>
          <p className={`mt-6 text-left text-sm font-normal text-[#999999] sm:mt-2`}>
            We’ve compiled a list of common inventory items for you to consider. Please select the items that are
            relevant to your business. Note that "Parts" is mandatory, so you won’t be able to deselect that option.
          </p>
          <p className={`mt-4 text-left text-sm font-normal text-[#999999] sm:mt-2`}>
            On the next screen, you'll have the opportunity to remove any selected inventory items or add additional
            ones.
          </p>
          <InventorySelectableButtons
            selectedTitles={selectedTitles}
            setSelectedTitles={setSelectedTitles}
            inventoryData={inventoryData}
          />
        </div>
        <ResponsiveBookOnboardingButton />
      </div>
      <PSBLOnboardingFooter isChatWidget onPrimaryClick={() => onScreenChange(CurrentScreenEnum.EditableTableView)} />
    </div>
  );
};

interface IEditableTableView {
  handleNext: () => void;
  selectedTitles: IMarketPlaceInventoryTemplates[];
  inventoryData?: IGetInventoryTemplates;
  setSelectedTitles: React.Dispatch<React.SetStateAction<IMarketPlaceInventoryTemplates[]>>;
}

export interface ISelectedInventoryValue {
  marketplaceID: number;
  inventoryCode: string;
  shortDescription: string;
  expenseAccount?: string;
}

const EditableTableView = (props: IEditableTableView) => {
  // Props
  const {handleNext, selectedTitles, inventoryData, setSelectedTitles} = props;

  // State
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const [isShowInventorySelectionModal, setIsShowInventorySelectionModal] = useState<boolean>(false);

  // API
  const {saveInventories, isLoading} = useOnboarding();

  useEffect(() => {
    if (!isValidField()) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  }, [selectedTitles]);

  const handleDropdownChange = (rowKey: number, value: string) => {
    setSelectedTitles(prevState => {
      const updatedRows = [...prevState];
      updatedRows[rowKey] = {...updatedRows[rowKey], expenseAccount: value};
      return updatedRows;
    });
  };

  const handleDeleteRow = (index: number) => {
    setSelectedTitles(prevRows => {
      const updatedRows = [...prevRows];
      updatedRows.splice(index, 1);
      return updatedRows;
    });
  };

  const isValidField = () => {
    const hasEmptyFields = selectedTitles.some(
      item => !item.inventoryCode.trim() || !item.shortDescription.trim() || !(item.expenseAccount?.trim() || ''),
    );
    if (hasEmptyFields) {
      return false;
    }
    return true;
  };

  const handleInventoryNext = async () => {
    setErrorMessage('');
    if (!isValidField()) {
      setErrorMessage('Please fill in all required fields before proceeding.');
      return;
    }

    const seenItemCodes = new Set();
    const seenDescriptions = new Set();

    const duplicateItemCode = selectedTitles.find(item => {
      const lowerCasedItemCode = item.inventoryCode.trim().toLowerCase();
      if (seenItemCodes.has(lowerCasedItemCode)) return true;
      seenItemCodes.add(lowerCasedItemCode);
      return false;
    });

    if (duplicateItemCode) {
      setErrorMessage('Duplicate item code found. Please make sure all item codes are unique.');
      return;
    }

    const duplicateDescription = selectedTitles.find(item => {
      const lowerCasedDescription = item.shortDescription.trim().toLowerCase();
      if (seenDescriptions.has(lowerCasedDescription)) return true;
      seenDescriptions.add(lowerCasedDescription);
      return false;
    });

    if (duplicateDescription) {
      setErrorMessage('Duplicate inventory item found. Please make sure all inventory items are unique.');
      return;
    }

    const formattedData = {
      inventories: selectedTitles.map(item => ({
        inventoryCode: item.inventoryCode,
        shortDescription: item.shortDescription,
        expenseAccount: item.expenseAccount || '',
      })),
    };

    try {
      await saveInventories(formattedData);
      handleNext();
    } catch (error) {}
  };

  const inventorySetupColumns = [
    {
      title: 'Item codes',
      key: 'itemCodes',
      align: 'left',
      width: '20%',
      columClassName: 'text-spenda-labeltext',
      rowClassName: 'p-1',
      rowRenderer: (rowData: ISelectedInventoryValue) => {
        return (
          <Typography data-autoid={`lblInventoryItems`} className=" p-[10px] font-medium text-black-900">
            {rowData?.inventoryCode}
          </Typography>
        );
      },
    },
    {
      title: 'Inventory items',
      key: 'inventoryItems',
      width: '30%',
      align: 'left',
      columClassName: 'text-spenda-labeltext',
      rowClassName: 'p-1',
      rowRenderer: (rowData: ISelectedInventoryValue) => {
        return (
          <Typography data-autoid={`lblInventoryItems`} className=" p-[10px] font-medium text-black-900">
            {rowData?.shortDescription}
          </Typography>
        );
      },
    },
    {
      title: 'Purchase account',
      key: 'purchaseAccount',
      width: '50%',
      align: 'left',
      columClassName: 'text-spenda-labeltext',
      rowClassName: 'p-0',
      rowRenderer: (rowData: ISelectedInventoryValue, index: number) => {
        return (
          <div className="p-1">
            <SelectCOADropdown
              type={ChartsOfAccountType.Expense}
              onChange={value => handleDropdownChange(index, value)}
              value={selectedTitles[index].expenseAccount}
              defaultPlaceholder="Select a purchase account"
              className="!border-none  !outline-none disabled:!bg-transparent"
              inputProps={{
                name: 'purchaseAccountdropdown',
              }}
            />
          </div>
        );
      },
    },
    {
      key: 'deleteItem',
      width: '10%',
      align: 'left',
      columClassName: 'bg-white',
      rowClassName: 'p-2.5 bg-white border-b border-white',
      rowRenderer: (rowData: ISelectedInventoryValue, index: number) => {
        const firstPartsIndex = selectedTitles.findIndex(item => item.shortDescription === 'Parts');
        if (rowData.shortDescription === 'Parts' && index === firstPartsIndex) {
          return <></>;
        }
        return (
          <Delete
            data-autoid={`btnDeleteLine`}
            className="cursor-pointer text-[#CCCCCC]"
            onClick={() => handleDeleteRow(index)}
          />
        );
      },
    },
  ];

  const WebTableView = (
    <div className={`mb-0 mt-6 flex h-full w-full !max-w-[606px] flex-col items-start overflow-x-auto bg-white`}>
      <ARTable
        conatinerClass={`${selectedTitles.length <= 4 ? '!overflow-y-visible' : '!overflow-y-auto'} !w-[606px] !h-auto !px-0`}
        isLoading={false}
        columns={inventorySetupColumns}
        rows={selectedTitles}
        dataAutoId="inventoryAccountSetup"
        getRowDataAutoId={rowData => `rowInventorySetup-${rowData?.ID}`}
      />
      {errorMessage && (
        <Typography className="inline w-fit overflow-ellipsis font-medium text-error" data-autoid="txtErrorMessage">
          {errorMessage}
        </Typography>
      )}
      <Typography
        className="inline w-fit cursor-pointer overflow-ellipsis font-medium text-primary"
        data-autoid="lnkAddNewInventoryItem"
        onClick={() => setIsShowInventorySelectionModal(true)}
      >
        Select more Inventory Items
      </Typography>
    </div>
  );

  const mobileTableView = (
    <div className="my-5 flex w-full flex-col gap-2.5">
      {selectedTitles.map((item, index) => (
        <div className="flex h-auto w-full flex-col rounded-[6px] bg-[#fafafa] p-2.5" key={index}>
          <p className="text-base font-medium text-black-800">
            {item?.inventoryCode} - {item?.shortDescription}
          </p>
          <div className="mt-3 flex w-full flex-row">
            <SelectCOADropdown
              type={ChartsOfAccountType.Expense}
              onChange={value => handleDropdownChange(index, value)}
              value={selectedTitles[index].expenseAccount}
              label="Select a purchase account"
              className=" !outline-none disabled:!bg-transparent"
              inputProps={{
                name: 'purchaseAccountdropdown',
              }}
            />
            {item?.inventoryCode !== 'CAPPARTS' && (
              <IconButton
                variant="outlined"
                onClick={() => handleDeleteRow(index)}
                className="ml-2.5 min-h-[45px] min-w-[45px]"
              >
                <IconDeleteLMI />
              </IconButton>
            )}
          </div>
        </div>
      ))}
      {errorMessage && (
        <Typography className="inline w-fit overflow-ellipsis font-medium text-error" data-autoid="txtErrorMessage">
          {errorMessage}
        </Typography>
      )}
      <Typography
        className="mt-2 inline w-full cursor-pointer overflow-ellipsis text-center font-medium text-primary"
        data-autoid="lnkAddNewInventoryItem"
        onClick={() => setIsShowInventorySelectionModal(true)}
      >
        Select more Inventory Items
      </Typography>
    </div>
  );

  return (
    <div className="wrapper relative h-full w-full overflow-hidden bg-white pb-24 font-poppins">
      <div className={`mx-auto flex h-full w-full flex-col justify-between overflow-y-auto sm:max-w-[622px] sm:pb-0`}>
        <div className="flex h-full flex-col">
          <div className={`mx-auto max-w-[622px] align-middle`}>
            <p className={`text-left font-poppins text-[20px] font-medium text-[#333] sm:text-2xl sm:font-light`}>
              Let’s get your inventory set up
            </p>
            <p className={`mt-6 text-left text-sm font-normal text-[#999999] sm:mt-2`}>
              You'll need to make sure you have inventory items set up for everything you usually purchase on your
              Capricorn trade account. To get you started, we've got a list of suggestions that we can create for you
              below..
            </p>
            <p className={`mt-4 text-left text-sm font-normal text-[#999999] sm:mt-2`}>
              Don't worry, if you don't get this right the first time we can import more from your financial system
              later
            </p>
          </div>
          {isMobile ? mobileTableView : WebTableView}
          {isShowInventorySelectionModal && (
            <InventoryItemsSelectionModal
              selectedTitles={selectedTitles}
              onClose={() => setIsShowInventorySelectionModal(false)}
              setSelectedTitles={setSelectedTitles}
              inventoryData={inventoryData}
            />
          )}
        </div>
        <ResponsiveBookOnboardingButton />
      </div>
      <PSBLOnboardingFooter
        isChatWidget
        isDisabled={isDisabled}
        onPrimaryClick={handleInventoryNext}
        isLoading={isLoading}
      />
    </div>
  );
};
