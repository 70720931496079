import React, {useContext, useEffect, useRef, useState} from 'react';
import Skeleton from 'react-loading-skeleton';
import {DatTypes} from '../../model/constants/Constants';
import {IWorkflowStatusResponse} from '../../model/workflow/WorkflowResponse';
import useWorkflowAPI from '../../services/useWorkflowAPI';
import useMasterAdaptorAPI from '../../services/useMasterAdaptorAPI';
import {css} from 'glamor';
import {RefreshIcon} from '../../assets/svg/RefreshIcon';
import {IntegrationContext} from '../../context/IntegrationContext';
import {Button} from 'spenda-ui-react';
import MyobLogo from '../../assets/svg/MyobLogo';
import QuickbooksLogo from '../../assets/svg/QuickbooksLogo';
import XeroLogoNew from '../../assets/svg/XeroLogoNew';

interface IArRefreshProps {
  handleRefreshData?: () => void;
  datTypeId: DatTypes | undefined;
  showOnlyRefreshIcon?: boolean;
  hideAdapterImage?: boolean;
  isNewBtn?: boolean;
  dataAutoID?: string;
  workflowtype?: string;
  title: string;
  height?: string;
  width?: string;
  lastExecDateTimeOverride?: string;
}

export const ArRefreshBtn = (props: IArRefreshProps) => {
  const {
    handleRefreshData,
    datTypeId,
    showOnlyRefreshIcon,
    dataAutoID,
    title,
    isNewBtn,
    height,
    width,
    lastExecDateTimeOverride,
    hideAdapterImage,
    workflowtype,
  } = props;
  const {isLoading: isAdaptorLoading} = useMasterAdaptorAPI();
  const {checkWorkflowStatus, lastSyncDate, queueWorkflow} = useWorkflowAPI();
  const workflowStatusTimer = useRef<NodeJS.Timeout | null>(null);

  const [isLoading, setIsLoading] = useState(false);
  const {financialAdaptor} = useContext(IntegrationContext);
  const [lastSyncDays, setLastSyncDays] = useState<number>();

  useEffect(() => {
    getLastSyncDate();
    return () => {
      workflowStatusTimer?.current && clearInterval(workflowStatusTimer?.current);
    };
  }, []);

  const getLastSyncDate = async () => {
    try {
      if (datTypeId) {
        const response = await lastSyncDate(datTypeId);
        if (response?.length) {
          setLastSyncDays(response[0].lastSyncDays);
        }
      }
    } catch {}
  };

  const handleRefreshClick = async () => {
    try {
      if (financialAdaptor?.Name && financialAdaptor?.ID) {
        setIsLoading(true);

        const payload = {
          Action: 'Read',
          AdaptorName: financialAdaptor.Name,
          AdaptorID: financialAdaptor.ID,
          DatTypeID: datTypeId,
          MaxWaitSecs: 29,
          IsSyncDependencies: true,
          IsImportUnPaidInvoicesOnly: true,
          WorkflowType: workflowtype,
          LastExecDateTimeOverride_utc: lastExecDateTimeOverride,
        };

        const synkDataRes = await queueWorkflow(payload);

        if (workflowStatusTimer.current) {
          clearInterval(workflowStatusTimer.current);
        }

        workflowStatusTimer.current = setInterval(() => {
          synkDataRes?.WorkflowID && checkWorkflowStatusFun(synkDataRes.WorkflowID);
        }, 5000);
      }
    } catch {
      setIsLoading(false);
    }
  };

  const checkWorkflowStatusFun = (workFlowId: number) => {
    if (workFlowId) {
      checkWorkflowStatus(workFlowId)
        .then((data: IWorkflowStatusResponse) => {
          if (data.Status === 'Complete') {
            if (handleRefreshData) {
              handleRefreshData();
            }
            setIsLoading(false);
            if (workflowStatusTimer?.current) {
              clearTimeout(workflowStatusTimer.current);
            }
            getLastSyncDate();
          }
        })
        .catch(() => {
          setIsLoading(false);
        });
    }
  };

  const getAdaptorImage = () => {
    switch (financialAdaptor?.Name) {
      case 'Xero':
        return <XeroLogoNew className="ml-2 !h-[24px] !w-[24px]" />;
      case 'MyObAccountRightLive':
        return <MyobLogo className="ml-2 !h-[22px] !w-[45px]" />;
      case 'QuickBooksOnline':
        return <QuickbooksLogo className="ml-2 !h-[20px] !w-[70px]" />;
      case 'Parody':
        return undefined;
      default:
        return undefined;
    }
  };

  const animationSpin = css({
    animation: 'spin 2s linear infinite',
  });

  return (
    <>
      {isAdaptorLoading ? (
        <div className={`flex`}>
          <Skeleton circle={true} width={20} height={20} />
          <div className="mx-2 flex flex-col">
            <Skeleton width={50} height={20} />
          </div>
          <Skeleton circle={true} width={20} height={20} />
        </div>
      ) : isNewBtn ? (
        <Button
          type="button"
          variant="outlined"
          color="primary"
          data-autoid={dataAutoID}
          onClick={() => (!isLoading ? handleRefreshClick() : null)}
        >
          <div className="flex items-center gap-x-2">
            {title} <RefreshIcon height="14px" width="14px" className={`${isLoading ? 'animate-spin' : ''}`} />
          </div>
        </Button>
      ) : (
        <div
          data-autoid={dataAutoID ? dataAutoID : 'btnARRefresh'}
          className={`flex cursor-pointer flex-row items-center`}
          onClick={() => (!isLoading ? handleRefreshClick() : null)}
        >
          <RefreshIcon
            height={height || '14px'}
            width={width || '14px'}
            className={`${isLoading ? animationSpin : ''} min-w-[14px]`}
          />
          {!showOnlyRefreshIcon && (
            <>
              <div className="ml-2 flex flex-col">
                <p className={`mb-0 whitespace-nowrap font-poppins text-base font-semibold leading-[1.1] text-primary`}>
                  {title}
                </p>
                <p className={`whitespace-nowrap font-poppins text-[10px] font-medium leading-[1.1] text-[#999999]`}>
                  Last Sync {lastSyncDays} days ago
                </p>
              </div>
              {!hideAdapterImage && getAdaptorImage()}
            </>
          )}
        </div>
      )}
    </>
  );
};
