import React, {useState} from 'react';
import {useFormikContext} from 'formik';
import {isEmpty} from 'lodash';
import {useHistory} from 'react-router-dom';
import {Button, Menu, MenuHandler, MenuList} from 'spenda-ui-react';

import {ApproveClaimModal} from '../../../components/AccountsReceivable/modals/ApproveClaimModal';
import {DeleteClaimModal} from '../../../components/AccountsReceivable/modals/DeleteClaimModal';
import {RejectClaimModal} from '../../../components/AccountsReceivable/modals/RejectClaimModal';
import {SendClaimModal} from '../../../components/AccountsReceivable/modals/SendClaimModal';
import {useTenantInfo} from '../../../hooks/useTenantInfo';
import {ClaimsAndReturnsStatus} from '../../../model/constants/Constants';
import {IClaimRequestFormValues} from './CreateAndEditClaimRequest';
import {IconDropDownArrow} from '../../../assets/svg/IconDropDownArrow';

interface ICreateAndEditClaimRequestFooterProps {
  redirectIfStatus: ClaimsAndReturnsStatus[];
  isSubmitting: boolean;
  claimAndReturnsV272602: boolean;
  updateClaimsStatus: (status: ClaimsAndReturnsStatus, rejectReason?: string) => Promise<void>;
}

export const CreateAndEditClaimRequestFooter = (props: ICreateAndEditClaimRequestFooterProps) => {
  // Props
  const {redirectIfStatus, claimAndReturnsV272602, isSubmitting, updateClaimsStatus} = props;

  // Hooks
  const formikProps = useFormikContext<IClaimRequestFormValues>();
  const {isInBuyerContext, isInSupplierContext} = useTenantInfo();
  const history = useHistory();

  // States
  const [isShowDeleteClaimDialog, setIsshowDeleteClaimDialog] = useState(false);
  const [isShowApproveClaimDialog, setIsShowApproveClaimDialog] = useState(false);
  const [isShowRejectClaimDialog, setIsShowRejectClaimDialog] = useState(false);
  const [isShowSendClaimDialog, setIsshowSendClaimDialog] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const isShowButton = (button: string, fValues: IClaimRequestFormValues) => {
    if (redirectIfStatus?.includes(fValues?.status)) {
      return false;
    }

    switch (button) {
      case 'Delete':
        if (isInBuyerContext) {
          return fValues?.claimRequestID && fValues?.status === ClaimsAndReturnsStatus.Draft;
        } else if (isInSupplierContext) {
          return fValues?.status === ClaimsAndReturnsStatus.Draft && fValues?.claimRequestID;
        }
        return false;
      case 'Save':
        if (isInBuyerContext) {
          return fValues?.status === ClaimsAndReturnsStatus.Draft;
        } else if (isInSupplierContext) {
          return true;
        }
        return false;
      case 'Send':
        if (isInBuyerContext) {
          return fValues?.status === ClaimsAndReturnsStatus.Draft;
        } else if (isInSupplierContext) {
          return fValues?.status === ClaimsAndReturnsStatus.Draft;
        }
        return false;
      case 'ApproveAndReviewed':
      case 'Reject':
      case 'Approve':
        const showApproveAndRejectBtnStatusFor = [ClaimsAndReturnsStatus.Sent, ClaimsAndReturnsStatus.Reviewed];
        return (
          fValues?.claimRequestID && isInSupplierContext && showApproveAndRejectBtnStatusFor?.includes(fValues?.status)
        );
      default:
        return false;
    }
  };

  const ActionButtons = ({
    btnLabel,
    dataAutoid,
    disabled,
    ...rest
  }: React.ComponentProps<'li'> & {
    btnLabel: string;
    dataAutoid: string;
    disabled?: boolean;
  }) => {
    return (
      <li
        {...rest}
        className={`w-full cursor-pointer select-none list-none border-b border-[#ECECEC] px-3 py-2 text-left text-base font-semibold text-black-800 hover:bg-primary/10 ${disabled ? 'opacity-50' : null}`}
        data-autoid={dataAutoid}
        onClick={e => {
          if (disabled) return;
          rest.onClick && rest.onClick(e);
        }}
      >
        {btnLabel}
      </li>
    );
  };

  const handleReviewAndApprove = (action: ClaimsAndReturnsStatus) => {
    formikProps?.setFieldValue('actionStatus', action);
    if (action === ClaimsAndReturnsStatus?.Approved) {
      setIsShowApproveClaimDialog(true);
      return;
    }
    formikProps?.handleSubmit();
  };

  return (
    <>
      <div className="absolute bottom-2.5 left-2.5 right-2.5 z-[0] mx-2.5 flex h-[60px] flex-row items-center justify-between rounded-[8px] bg-[#f0f0f0] p-2.5">
        {/* Left Side Buttons */}
        <div className="flex flex-row items-center justify-start gap-2.5">
          <Button variant="outlined" className="bg-white" onClick={() => history.goBack()}>
            Cancel
          </Button>

          {isShowButton('Save', formikProps?.values) && (
            <Button
              disabled={isSubmitting || !formikProps?.values?.lineItems?.length}
              loading={isSubmitting}
              onClick={async () => {
                formikProps?.setFieldValue('actionStatus', undefined);
                formikProps?.handleSubmit();
              }}
            >
              Save
            </Button>
          )}
        </div>

        {/* Right Side Buttons */}
        <div className="flex flex-row items-center justify-end gap-2.5">
          {isShowButton('Delete', formikProps?.values) && (
            <Button
              color="error"
              variant="outlined"
              className="bg-white"
              data-autoid={`btnCreateAndEditClaimRequestDelete`}
              onClick={() => setIsshowDeleteClaimDialog(true)}
            >
              Delete
            </Button>
          )}
          {isShowButton('Send', formikProps?.values) && (
            <Button
              disabled={isSubmitting || !formikProps?.values?.lineItems?.some(item => item?.quantity > 0)}
              onClick={async () => {
                const errs = await formikProps?.validateForm();
                if (!isEmpty(errs)) formikProps?.handleSubmit();
                else setIsshowSendClaimDialog(true);
              }}
            >
              Send
            </Button>
          )}

          {isShowButton('ApproveAndReviewed', formikProps?.values) && claimAndReturnsV272602 ? (
            <Menu open={isMenuOpen} handler={value => setIsMenuOpen(value)}>
              <MenuHandler className="w-[180px]">
                <Button data-autoid="btnClaimAction">
                  <div className="flex items-center justify-center gap-x-3">
                    Action Claim
                    <IconDropDownArrow
                      width="10"
                      height="10"
                      className={`${isMenuOpen ? '-rotate-180' : ''} cursor-pointer`}
                    />
                  </div>
                </Button>
              </MenuHandler>
              <MenuList className="w-[180px] overflow-hidden border-primary p-0">
                <ActionButtons
                  btnLabel="Approve"
                  dataAutoid="btnCreateAndEditClaimRequestCodeApprove"
                  onClick={() => handleReviewAndApprove(ClaimsAndReturnsStatus.Approved)}
                />

                <ActionButtons
                  btnLabel="Supplier Reviewing"
                  dataAutoid="btnSupplierReviewing"
                  disabled={formikProps?.values?.status === ClaimsAndReturnsStatus.Reviewed}
                  onClick={() => handleReviewAndApprove(ClaimsAndReturnsStatus.Reviewed)}
                />

                {isShowButton('Reject', formikProps?.values) && (
                  <ActionButtons
                    btnLabel="Reject"
                    dataAutoid="btnCreateAndEditClaimRequestCodeReject"
                    onClick={() => setIsShowRejectClaimDialog(true)}
                  />
                )}
              </MenuList>
            </Menu>
          ) : (
            isShowButton('Approve', formikProps?.values) && (
              <Button onClick={() => setIsShowApproveClaimDialog(true)}>Approve</Button>
            )
          )}
        </div>
      </div>

      <SendClaimModal
        isSubmitting={isSubmitting}
        isOpen={isShowSendClaimDialog}
        onConfirm={async () => {
          formikProps?.setFieldValue('actionStatus', ClaimsAndReturnsStatus.Sent);
          formikProps?.handleSubmit();
        }}
        onClose={() => setIsshowSendClaimDialog(false)}
      />

      <DeleteClaimModal
        isOpen={isShowDeleteClaimDialog}
        onConfirm={async () => {
          formikProps?.setFieldValue('actionStatus', ClaimsAndReturnsStatus.Deleted);
          updateClaimsStatus(ClaimsAndReturnsStatus.Deleted);
        }}
        onClose={() => setIsshowDeleteClaimDialog(false)}
        isSubmitting={isSubmitting}
      />

      <ApproveClaimModal
        isSubmitting={isSubmitting}
        isOpen={isShowApproveClaimDialog}
        onClose={() => setIsShowApproveClaimDialog(false)}
        onConfirm={async (referenceNo, paymentDate) => {
          formikProps?.setFieldValue('actionStatus', ClaimsAndReturnsStatus.Approved);
          claimAndReturnsV272602 && formikProps?.setFieldValue('approveReferenceNo', referenceNo);
          claimAndReturnsV272602 && formikProps?.setFieldValue('approvePaymentDate', paymentDate);
          formikProps?.handleSubmit();
        }}
      />

      <RejectClaimModal
        isSubmitting={isSubmitting}
        isOpen={isShowRejectClaimDialog}
        onClose={() => setIsShowRejectClaimDialog(false)}
        onConfirm={reason => {
          formikProps?.setFieldValue('actionStatus', ClaimsAndReturnsStatus.Rejected);
          updateClaimsStatus(ClaimsAndReturnsStatus.Rejected, reason);
        }}
      />
    </>
  );
};
