import React, {SetStateAction, useEffect, useMemo, useState} from 'react';
import {getIn, useFormikContext} from 'formik';
import {
  Input,
  Select,
  Textarea,
  Typography,
  Option,
  Checkbox,
  Switch,
  Card,
  CardBody,
  Button,
  Chip,
  IconButton,
  Tooltip,
  Calendar,
} from 'spenda-ui-react';
import moment from 'moment';
import _ from 'lodash';

import {ServiceLocationCard} from './ServiceLocationCard';
import CalendarIcon from '../../../assets/svg/Calendar';
import {IUserAccountInfo, UserRoleEnum} from '../../../model/user/UserAccountInfo';
import useUsersAPI from '../../../services/useUsersAPI';
import {SelectShippingAddressDialog} from '../../../components/dialog/SelectShippingAddressDialog';
import {ICustomer, ICustomerAsset} from '../../../model/customer/Customer';
import {IAddress} from '../../../model/address/Address';
import {ILocation} from '../../../model/address/Location';
import {SelectAssetDialog} from '../../../components/dialog/SelectAssetDialog';
import {AddressDialog} from '../../../components/dialog/AddressDialog';
import {
  IServiceBooking,
  IServiceJob,
  IServiceJobTechnician,
  ITechnician,
  ServiceJobStatus,
  TravelTimeBuffer,
} from '../../../model/service-management/serviceJob';
import {useCustomersAssetAPI} from '../../../services/useCustomersAssetAPI';
import EditCustomerIcon from '../../../assets/svg/EditCustomerIcon';
import {Actions} from '../../../model/constants/Constants';
import {WidgetDeleteLogo} from '../../../assets/svg/WidgetDeleteLogo';
import {getLocation} from '../../../utils/customerAddressHelper';
import {Toast} from '../../../utils/Toast';
import useServiceJobAPI from '../../../services/useServiceJobAPI';
import blueInfoIcon from '../../../assets/svg/accounts-payable/blue-info-icon.svg';
import {PlusButton} from '../../../assets/svg';
import {AlertDialogSlideV2} from '../../../components/dialog/AlertDialogSlideV2';
import {useServiceContext} from '../../../context/serviceManagement/ServiceContext';
import {BookOnCalendarDialogContextProvider} from './context/BookOnCalendarDialogContextProvider';
import {useBookOnCalendarDialogContext} from './context/BookOnCalendarDialogContext';
import {LoadingOverlayV1} from '../../../components/ui/LoadingOverlayV1';
import {currentActiveView} from 'spenda-ui-react/types/components/calendar';
import {BookingDiaryWeekView} from '../../../components/bookingDiary/BookingDiaryWeekView';
import FilterTechnician from '../../../components/bookingDiary/FilterTechnician';
import SchedulerDialog from '../../../components/dialog/SchedulerDialog';

export default function ServiceDetailTab(props: {
  handleUpdateOpCustomer: (location: ILocation[]) => void;
  customerDetails?: ICustomer;
  setIsLoading: React.Dispatch<SetStateAction<boolean>>;
}) {
  const {customerDetails, handleUpdateOpCustomer, setIsLoading} = props;
  const {values, errors, touched, handleBlur, handleSubmit, setFieldValue} = useFormikContext<IServiceJob>();

  const {saveCustomerAsset, fetchAssetDetails, updateCustomerAsset, getCustomerAsset} = useCustomersAssetAPI();

  const initialAssetValues = [
    {
      ID: null,
      Addresses: [
        {
          Country: '',
          State: '',
          StreetAddress: '',
          City: '',
          PostCode: '',
        },
      ],
    },
  ];

  // States
  const [technicians, setTechnicians] = useState<ITechnician[]>([]);
  const [selectedTechnicians, setSelectedTechicians] = useState<{user: IServiceJobTechnician; isSelected: boolean}[]>(
    [],
  );
  const [users, setUsers] = useState<IUserAccountInfo[]>([]);
  const [showLocationModal, setShowLocationModal] = useState<boolean>(false);
  const [addressType, setAddressType] = useState<'BillingAddress' | 'ShippingAddress'>();
  const [showAssetModal, setShowAssetModal] = useState<boolean>(false);
  const [createNewAssetDialog, setCreateNewAssetDialog] = useState<boolean>(false);
  const [locations, setLocations] = useState<ILocation[]>(initialAssetValues);
  const [bookViaCalendar, setBookViaCalendar] = useState<boolean>(true);
  const [selectedAsset, setSelectedAsset] = useState<ICustomerAsset>();
  const [travelBufferTime, setTravelBufferTime] = useState<string>();
  const travelBufferKeys = Object.keys(TravelTimeBuffer) as (keyof typeof TravelTimeBuffer)[];
  const [shippingAddress, setShippingAddress] = useState<IAddress>();
  const [billingAddress, setBillingAddress] = useState<IAddress>();
  const [activeBookings, setActiveBookings] = useState<IServiceBooking[]>();
  const [isEditingAsset, setIsEditingAsset] = useState<boolean>(false);
  const [collapseAsset, setCollapseAsset] = useState<boolean>(false);
  const [savedTechnicians, setSavedTechnicians] = useState<IServiceJobTechnician[]>();
  const [showBookOnCalendar, setShowBookOnCalendar] = useState<boolean>(false);
  const [isCustomerAssetExist, setIsCustomerAssetExist] = useState<ICustomerAsset[]>();

  const {get} = useUsersAPI();
  const {getServiceTechnicians} = useServiceJobAPI();

  const fetchServiceDetails = async () => {
    try {
      const results = await Promise.all([
        !users.length && get({}),
        values?.assetID && fetchAssetDetails(values?.assetID!),
        !technicians.length && getServiceTechnicians(),
        values?.customerID &&
          getCustomerAsset(values?.customerID!, {customerID: values.customerID, MaxResults: 1000, StartRow: 1}),
      ]);
      if (!users.length && results[0] && results[0]?.IsSuccess) {
        const filteredSalesRepUsers = results[0]?.Items.filter(
          user => !user.UserRoles.some(role => role.UserRoleEnum === UserRoleEnum.Technician),
        );
        setUsers(filteredSalesRepUsers);
      }
      if (values?.assetID && results[1]) {
        setSelectedAsset(results[1]);
      }
      if (results[2] && results[2].length) {
        setTechnicians(results[2]);
      }
      if (values?.customerID && results[3]) {
        setIsCustomerAssetExist(results[3]);
      }
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    fetchServiceDetails();
    if (values?.travelTimeBufferInMinutes) {
      const travelTime = () => {
        switch (values?.travelTimeBufferInMinutes!) {
          case 0:
            return TravelTimeBuffer.NONE;
          case 15:
            return TravelTimeBuffer.MIN_15;
          case 30:
            return TravelTimeBuffer.MIN_30;
          case 45:
            return TravelTimeBuffer.MIN_45;
          case 60:
            return TravelTimeBuffer.MIN_60;
        }
      };
      setTravelBufferTime(travelTime);
    }
    setActiveBookings(values?.bookings?.filter(booking => booking.isActive));
    setSavedTechnicians(values?.technicians || []);
  }, [values?.serviceJobID]);

  useEffect(() => {
    technicians?.length && getSelectedTechicians();
  }, [savedTechnicians, technicians]);

  const getSelectedTechicians = () => {
    const selectedTechnicians = technicians?.map(user => {
      const isAlreadySelected = savedTechnicians?.find(v => v.technicianUserID === user.userID) ? true : false;
      const technicianUser: IServiceJobTechnician = {
        lastName: user.lastName,
        firstName: user.firstName,
        technicianUserID: user.userID,
        action: isAlreadySelected ? Actions.update : Actions.add,
      };
      const isSelected = isAlreadySelected || false;
      return {
        user: technicianUser,
        isSelected,
      };
    });
    const updatedTech = savedTechnicians?.map(tech => {
      return {...tech, action: Actions.add};
    });

    values?.serviceJobID && setFieldValue('technicians', updatedTech);
    setSelectedTechicians(selectedTechnicians || []);
  };

  useEffect(() => {
    async function getAddresses() {
      if (customerDetails?.Locations) {
        const {ShippingAddress, BillingAddress} = await getLocation(customerDetails?.Locations || []);
        setShippingAddress(ShippingAddress);
        setBillingAddress(BillingAddress);
      }
    }
    customerDetails?.Locations?.length && getAddresses();
  }, [customerDetails]);

  // Key Press event
  const handleKeyDown = (event: any) => {
    if (event.key === 'Backspace') {
      onRemoveSelected(values?.technicians?.at(values?.technicians?.length - 1));
    }
  };

  // On Remove selected contact
  const onRemoveSelected = (user?: IServiceJobTechnician) => {
    if (!user) return;
    // if user is already saved set action remove else filter
    const isAlreadySavedTech = savedTechnicians?.find(tech => tech.technicianUserID === user.technicianUserID);
    let updatedTechnicians = [];
    if (isAlreadySavedTech) {
      const existingTech = [...(values?.technicians || [])];
      updatedTechnicians = existingTech.map(tech => {
        if (tech.technicianUserID === user.technicianUserID) {
          tech.action = Actions.remove;
        }
        return tech;
      });
    } else {
      updatedTechnicians = values?.technicians?.filter(v => v.technicianUserID !== user?.technicianUserID) || [];
    }
    const isPrimaryRemoved =
      values?.technicians?.find(v => v.technicianUserID == user?.technicianUserID) &&
      user.technicianUserID == values?.primaryTechnicianUserID;
    if (isPrimaryRemoved) {
      if (updatedTechnicians.length) {
        setPrimaryTechnician(updatedTechnicians.find(tech => tech.action != Actions.remove));
      } else {
        setPrimaryTechnician();
      }
    }
    setSelectedTechicians(prev => {
      const currentValue = prev.map(v => {
        if (v.user.technicianUserID === user.technicianUserID) {
          v.isSelected = false;
        }
        return v;
      });
      return currentValue;
    });
    setFieldValue('technicians', updatedTechnicians);
  };

  const setPrimaryTechnician = (user?: IServiceJobTechnician) => {
    setFieldValue('primaryTechnicianFirstName', user?.firstName || '');
    setFieldValue('primaryTechnicianLastName', user?.lastName || '');
    setFieldValue('primaryTechnicianUserID', user?.technicianUserID || '');
  };

  // On Change select function
  const onMultiSelectChange = async (userID: string) => {
    let latest = selectedTechnicians.find(v => `${v.user.technicianUserID}` === userID);
    if (!latest) return;
    await setSelectedTechicians(prev => {
      let latestOptions = prev.map(v => {
        if (`${v.user.technicianUserID}` === userID) {
          v.isSelected = !v.isSelected;
        }
        return v;
      });
      return latestOptions;
    });
    if (latest && latest.isSelected === false) {
      const updatedTechnicians =
        values?.technicians?.map(v => {
          if (`${v.technicianUserID}` === userID) {
            return {...v, action: Actions.remove};
          }
          return v;
        }) || [];
      const isPrimaryRemoved =
        values?.technicians?.find(v => v.technicianUserID == parseInt(userID)) &&
        userID == values?.primaryTechnicianUserID;
      if (isPrimaryRemoved) {
        if (updatedTechnicians.length) {
          setPrimaryTechnician(updatedTechnicians.find(tech => tech.action != Actions.remove));
        } else {
          setPrimaryTechnician();
        }
      }
      setFieldValue('technicians', updatedTechnicians);
    } else {
      let technicians = values?.technicians ? [...values?.technicians] : [];
      // check if user already exist
      let newTechnicianIndex = technicians.findIndex(tech => tech.technicianUserID === latest?.user?.technicianUserID);
      let newTechnician;
      if (newTechnicianIndex !== -1) {
        technicians[newTechnicianIndex].action = Actions.add;
        newTechnician = technicians[newTechnicianIndex];
        setFieldValue('technicians', technicians);
      } else {
        newTechnician = {...latest.user, action: Actions.add};
        technicians.push(newTechnician);
        setFieldValue('technicians', technicians);
      }
      if (
        technicians.filter(tech => tech.action !== Actions.remove).length === 0 ||
        !values?.primaryTechnicianUserID ||
        values?.primaryTechnicianUserID == ''
      ) {
        setPrimaryTechnician(newTechnician);
      } else {
        setFieldValue(
          'technicians',
          _.sortBy(technicians, obj => {
            return obj.technicianUserID === values?.primaryTechnicianUserID ? 0 : 1;
          }),
        );
      }
    }
  };

  const createAsset = async (loc: ILocation[]) => {
    if (
      Array.isArray(loc) &&
      loc[loc.length - 1] &&
      Array.isArray(loc[loc?.length - 1].Addresses) &&
      loc[loc?.length - 1].Addresses?.length
    ) {
      const addresses = loc[loc?.length - 1].Addresses;
      if (typeof addresses == 'undefined') return;
      const location = addresses.at(0)!;
      // Update Asset Address
      if (selectedAsset?.assetID && isEditingAsset) {
        await updateCustomerAsset(selectedAsset?.businessLocationAddress.id!, {
          city: location?.City!,
          country: location.Country!,
          streetAddress: location.StreetAddress!,
          state: location.State!,
          postCode: location.PostCode!,
        });
        setSelectedAsset(selectedAsset);
        setShowAssetModal(true);
        setCreateNewAssetDialog(false);
        return;
      }
      // Create New Asset
      const payload: ICustomerAsset = {
        assetType: 'Facilities',
        assetFacilityType: 'House',
        businessLocationAddress: {
          city: location?.City!,
          country: location.Country!,
          streetAddress: location.StreetAddress!,
          state: location.State!,
          postCode: location.PostCode!,
        },
      };
      const newAsset = await saveCustomerAsset(values?.customerID!, payload);
      setShowAssetModal(true);
      setCreateNewAssetDialog(false);
      setSelectedAsset(newAsset);
    }
  };

  const onCopyShippingAddress = () => {
    if (shippingAddress?.ID) {
      const loc: ILocation = {
        Addresses: [
          {
            Country: shippingAddress?.Country,
            State: shippingAddress?.State,
            StreetAddress: shippingAddress?.StreetAddress,
            City: shippingAddress?.City,
            PostCode: shippingAddress?.PostCode,
          },
        ],
      };
      setLocations([loc]);
      setCollapseAsset(true);
    } else {
      Toast.warning('Please add a default shipping address');
      setCreateNewAssetDialog(false);
    }
  };

  // View of selected contact in multi select dropdown
  const viewSelected = (value: IServiceJobTechnician) => {
    return (
      <Chip
        key={value.technicianUserID}
        value={
          <div className="flex items-center gap-2">
            <span>{`${value.technicianUserID == values?.primaryTechnicianUserID ? 'Primary: ' : ''} ${value.firstName} ${value.lastName}`}</span>
            <span onClick={() => onRemoveSelected(value)} className="text-base text-[#ccc]">
              &times;
            </span>
          </div>
        }
        color="primary"
        className="h-[22px] rounded px-2 py-1.5 leading-[7px]"
      ></Chip>
    );
  };

  const disabled = useMemo(() => {
    return values?.status === ServiceJobStatus.Closed;
  }, [values?.status]);

  const disabledTechnicianAndBooking = useMemo(() => {
    return (
      values?.status === ServiceJobStatus.Scheduled ||
      values?.status === ServiceJobStatus.InProgress ||
      values?.status === ServiceJobStatus.Completed ||
      disabled
    );
  }, [values?.status, disabled]);

  return (
    <form onSubmit={handleSubmit} tabIndex={0} className="!outline-none">
      <div className="sm:max-h-auto flex flex-col justify-between overflow-auto sm:max-h-[400px] lg:max-h-[500px] lg:flex-row lg:px-4 xl:max-h-[550px] xl:px-12">
        <div className="basis-[40%] pb-2.5 pr-2">
          <Typography className="mb-4 text-lg font-medium text-black-800">Job details</Typography>
          <div className="flex flex-col gap-y-[15px]">
            {customerDetails?.Contacts?.length && (
              <Select
                name="contactCustomer"
                size="lg"
                variant="outlined"
                label={'Customer Contact'}
                value={values?.onSiteBusinessContactID?.toString()}
                disabled={customerDetails?.Contacts?.length === 1 || disabled}
                onChange={(value: string) => {
                  setFieldValue('onSiteBusinessContactID', value);
                }}
                arrow={customerDetails?.Contacts?.length !== 1 ? undefined : false}
              >
                {customerDetails?.Contacts?.map((options, index) => {
                  return (
                    <Option
                      value={options?.BusinessContactID?.toString()}
                      key={index}
                    >{`${options.FirstName} ${options.LastName}`}</Option>
                  );
                })}
              </Select>
            )}
            <Textarea
              name="preServiceNote"
              label={'Site contact'}
              color="primary"
              value={values?.preServiceNote}
              className="!h-[45px] min-h-[45px]"
              helperText={errors.preServiceNote && touched.preServiceNote ? errors.preServiceNote : ''}
              error={touched.preServiceNote && Boolean(errors.preServiceNote)}
              onChange={e => setFieldValue('preServiceNote', e.target.value)}
              disabled={disabled}
            />
            <Textarea
              name="description"
              label={'Job description'}
              color="primary"
              displayLength
              maxLength={200}
              helperText={errors.description ? errors.description : ''}
              error={Boolean(errors.description)}
              className="!h-[75px] min-h-[75px]"
              value={values?.description}
              onChange={e => setFieldValue('description', e.target.value)}
              disabled={disabled}
            />
            {users?.length && (
              <Select
                name="repUserID"
                size="lg"
                variant="outlined"
                label={'Sales Rep'}
                value={values?.repUserID?.toString()}
                onChange={(value: string) => {
                  setFieldValue('repUserID', value);
                }}
                disabled={disabled}
                menuProps={{className: 'max-h-48'}}
              >
                {users?.map((options, index) => {
                  return (
                    <Option value={options?.UserID?.toString()} key={index}>
                      {options.DisplayName}
                    </Option>
                  );
                })}
              </Select>
            )}
            <Select
              disabled={disabledTechnicianAndBooking}
              name="technicians"
              label={!Boolean(errors.technicians) ? 'Technicians' : 'Technicians (required)'}
              menuProps={{className: 'max-h-60'}}
              isMultiSelect
              viewOfSelected={v => {
                return v.action != Actions.remove && viewSelected(v);
              }}
              error={Boolean(errors.technicians)}
              inputProps={{
                readOnly: true,
                disabled: disabledTechnicianAndBooking,
                placeholder:
                  values?.technicians && values?.technicians?.filter(tech => tech.action !== Actions.remove).length > 0
                    ? ''
                    : 'Select from the dropdown the technicians for this job',
                autoFocus: false,
                onKeyDown: handleKeyDown,
                className: 'min-w-[64px]',
              }}
              value={values?.technicians}
              onChange={onMultiSelectChange}
            >
              <Typography className="border border-[#BBBBBB] p-3 text-sm font-normal leading-tight text-black-800 last:border-b-0">
                Please select the Primary Technician first.
              </Typography>
              {selectedTechnicians.map((options, index) => {
                return (
                  <Option
                    value={`${options.user.technicianUserID}`}
                    key={index}
                    className="flex justify-start gap-3 border-t-0 border-[#BBBBBB] p-4 px-2.5 last:border-b-0"
                  >
                    <div className="flex items-start justify-start">
                      <Checkbox
                        ripple={false}
                        readOnly
                        checked={options.isSelected}
                        value={`${options.user.firstName} ${options.user.lastName}`}
                        containerProps={{
                          className: 'p-0',
                        }}
                        data-autoid={`chkTechnician-${options.user.technicianUserID}`}
                        className="h-4 w-4 rounded-[4px] border-primary bg-white transition-all checked:border-primary checked:bg-primary hover:before:opacity-0"
                      />
                    </div>
                    <Typography className="font-semibold leading-tight text-black-800">
                      {`${options.user.firstName} ${options.user.lastName}`}
                    </Typography>
                  </Option>
                );
              })}
            </Select>
          </div>
          <Typography className="mt-[21px] text-lg font-medium text-black-800">Service location</Typography>
          <div className="flex gap-3">
            <ServiceLocationCard
              chipValue="Billing Address"
              placeholder="Add Billing Address"
              value={billingAddress?.CompleteAddress}
              className={`${!billingAddress?.ID && 'bg-[#BBDBED]'} ${disabled && '!cursor-not-allowed'}`}
              handleOnClick={() => {
                if (disabled) return;
                setShowLocationModal(true);
                setAddressType('BillingAddress');
              }}
              data_autoid={'cardBillingAddress'}
            />
            <ServiceLocationCard
              chipValue="Shipping Address"
              placeholder="Add Shipping Address"
              value={shippingAddress?.CompleteAddress}
              className={`${!shippingAddress?.ID && 'bg-[#E3EEF5]'} ${disabled && '!cursor-not-allowed'}`}
              handleOnClick={() => {
                if (disabled) return;
                setShowLocationModal(true);
                setAddressType('ShippingAddress');
              }}
              data_autoid={'cardShippingAddress'}
            />
          </div>
        </div>
        <div className="flex basis-[52%] flex-col gap-4 pr-2">
          <div className=" flex flex-col justify-center !gap-3 ">
            <div className="flex flex-row gap-1">
              <Typography variant="h3">Asset details</Typography>
              <Tooltip
                placement="right"
                offset="0"
                className="left-10 z-[9999] ml-1 rounded-[4px] bg-white px-3 py-2 text-black-800 shadow-xl shadow-black/10"
                content={`An Asset signifies what you're servicing, such as a location or vehicle.`}
              >
                <img
                  className="ml-1 cursor-pointer"
                  src={blueInfoIcon}
                  data-autoid={`imgBlueInfo`}
                  height="16px"
                  width={'16px'}
                />
              </Tooltip>
            </div>
            <Card
              onClick={() => {
                if (selectedAsset || disabled) return;
                isCustomerAssetExist?.length ? setShowAssetModal(true) : setCreateNewAssetDialog(true);
              }}
              className={`${selectedAsset ? null : 'cursor-pointer'} min-h-[112px] rounded-md border border-primary bg-[#E3EEF5] shadow-none`}
              data-autoid={`cardSelectAsset`}
            >
              <CardBody className="flex items-start justify-center p-[2.75rem] text-center">
                <Typography variant="h3" className="mx-auto w-full text-center font-poppins font-semibold text-primary">
                  {!showAssetModal && !createNewAssetDialog && selectedAsset?.assetID ? (
                    <>
                      {`${selectedAsset?.businessLocationAddress?.streetAddress},
                      ${selectedAsset?.businessLocationAddress?.city}, ${selectedAsset?.businessLocationAddress?.country},
                      ${selectedAsset?.businessLocationAddress?.state}, ${selectedAsset?.businessLocationAddress?.postCode}`}
                      <EditCustomerIcon
                        className={`absolute bottom-2 right-2 cursor-pointer ${disabled && '!cursor-not-allowed'}`}
                        onClick={(e: React.MouseEvent<SVGSVGElement>) => {
                          e.stopPropagation();
                          if (disabled) return;
                          setShowAssetModal(true);
                        }}
                        data-autoid={`btnEditAsset`}
                      />
                    </>
                  ) : (
                    'Select Asset'
                  )}
                </Typography>
              </CardBody>
            </Card>
            {Boolean(errors?.assetID) && (
              <Typography variant="small" className="mt-[-8px] text-error">
                {errors?.assetID}
              </Typography>
            )}
            <Switch
              id="isWaitingForStock"
              name="isWaitingForStock"
              ripple={false}
              className="h-full w-full checked:bg-success"
              containerProps={{
                className: 'w-11 h-6',
              }}
              disabled={disabled}
              checked={values?.isWaitingForStock}
              circleProps={{
                className: 'before:hidden left-0.5 border-none',
              }}
              label={
                <Typography className="font-medium text-black-900">Are you waiting for stock availabilty?</Typography>
              }
              onChange={() => setFieldValue('isWaitingForStock', !values?.isWaitingForStock)}
            />
          </div>
          <div className="rounded-[10px] bg-primary/10 p-5">
            <Typography className="mb-7 text-lg font-medium text-black-800">Schedule details</Typography>
            <div className="flex gap-7">
              <Input
                label="Time estimate"
                type="number"
                className="bg-white"
                containerProps={{className: 'min-w-[160px] !w-[160px]'}}
                value={values?.estimatedLabourHours}
                onChange={e => {
                  if (!/^\d*$/.test(e.target.value)) {
                    return;
                  }
                  setFieldValue('estimatedLabourHours', e.target.value);
                }}
                data-autoid={'txtTimeEstimate'}
                disabled={disabledTechnicianAndBooking}
              />
              <Switch
                id="bookViaCalendar"
                name="bookViaCalendar"
                ripple={false}
                className="h-full w-full checked:bg-success"
                containerProps={{
                  className: 'w-11 h-6',
                }}
                checked={bookViaCalendar}
                disabled={disabledTechnicianAndBooking}
                onChange={e => {
                  if (!e.target.checked && !activeBookings?.length) {
                    const defaultBooking = {
                      index: 0,
                      isActive: true,
                      scheduleDate: '',
                      startDateTime_utc: '',
                      endDateTime_utc: '',
                      startTime: '',
                      endTime: '',
                      action: Actions.add,
                    };
                    setFieldValue('bookings', [defaultBooking]);
                    setActiveBookings([defaultBooking]);
                  } else if (e.target.checked) {
                    // if true delete any empty rows from booking scheduler
                    let bookings = values.bookings && [...values.bookings];
                    bookings = bookings?.filter(
                      booking => booking.startTime != '' || booking.endTime != '' || booking.scheduleDate != '',
                    );
                    setFieldValue('bookings', bookings);
                    setActiveBookings(bookings?.filter(booking => booking.isActive));
                  }
                  setBookViaCalendar(!bookViaCalendar);
                }}
                circleProps={{
                  className: 'before:hidden left-0.5 border-none',
                }}
                label={<Typography className="font-medium text-black-900">Book via the calendar view</Typography>}
              />
            </div>
            {values?.bookings?.length
              ? values?.bookings.map((booking, index) => {
                  const disabledFields = disabledTechnicianAndBooking;
                  if (!booking.isActive) return null;
                  else
                    return (
                      <div className="flex items-center gap-x-1 pt-4" key={index}>
                        <Input
                          type="date"
                          label="Scheduled Date"
                          name="scheduleDate"
                          value={booking.scheduleDate}
                          onChange={e => {
                            setFieldValue(`bookings.${[index]}.scheduleDate`, e.target.value);
                          }}
                          disabled={disabledFields}
                          containerProps={{className: 'min-w-[150px] !w-[150px] mr-1'}}
                          className="!w-[150px]  bg-white"
                          helperText={getIn(errors, `bookings.${[index]}.scheduleDate`)}
                          error={Boolean(getIn(errors, `bookings.${[index]}.scheduleDate`))}
                        />
                        <Input
                          type="time"
                          label="Start Time"
                          name="StartTime"
                          width={'150px'}
                          value={booking.startTime}
                          onChange={e => {
                            setFieldValue(`bookings.${[index]}.startTime`, e.target.value);
                          }}
                          disabled={disabledFields}
                          containerProps={{className: 'min-w-[136px] !w-[136px] mr-1'}}
                          className="!w-[136px]  bg-white"
                          helperText={getIn(errors, `bookings.${[index]}.startTime`)}
                          error={Boolean(getIn(errors, `bookings.${[index]}.startTime`))}
                        />
                        <Input
                          type="time"
                          label="Finish Time"
                          name="FinishTime"
                          value={booking.endTime}
                          onChange={e => {
                            setFieldValue(`bookings.${[index]}.endTime`, e.target.value);
                          }}
                          disabled={disabledFields}
                          containerProps={{className: 'min-w-[136px] !w-[136px] mr-1'}}
                          className="!w-[136px]  bg-white"
                          helperText={getIn(errors, `bookings.${[index]}.endTime`)}
                          error={Boolean(getIn(errors, `bookings.${[index]}.endTime`))}
                        />
                        <IconButton
                          disabled={disabledFields}
                          className="cursor-pointer active:bg-transparent"
                          ripple={false}
                          variant="text"
                          onClick={() => {
                            let rows = values?.bookings && [...values?.bookings];
                            if (booking.serviceBookingID && rows && rows?.[index]) {
                              rows[index].isActive = false;
                              rows[index].action = Actions.remove;
                            } else rows?.splice(index, 1);
                            setFieldValue('bookings', rows);
                            setActiveBookings(rows?.filter(booking => booking.isActive));
                            setBookViaCalendar(true);
                          }}
                          data-autoid={'btnWidgetDelete'}
                        >
                          <WidgetDeleteLogo />
                        </IconButton>
                        {index === (activeBookings?.length ? activeBookings?.length - 1 : 0) && (
                          <IconButton
                            disabled={disabledFields}
                            className="cursor-pointer active:bg-transparent"
                            ripple={false}
                            variant="text"
                            size="sm"
                            onClick={() => {
                              if (bookViaCalendar) {
                                setShowBookOnCalendar(true);
                                return;
                              }

                              const rows = [
                                ...(values?.bookings || []),
                                {
                                  index: values?.bookings?.length,
                                  scheduleDate: '',
                                  startDateTime_utc: '',
                                  endDateTime_utc: '',
                                  startTime: '',
                                  endTime: '',
                                  action: Actions.add,
                                  isActive: true,
                                },
                              ];
                              setFieldValue('bookings', rows);
                              setActiveBookings(rows?.filter(booking => booking.isActive));
                            }}
                            data-autoid={'btnPlus'}
                          >
                            <PlusButton />
                          </IconButton>
                        )}
                      </div>
                    );
                })
              : null}
            {activeBookings?.length ? (
              <div className="mt-4">
                <Select
                  name="travelTimeBufferInMinutes"
                  size="lg"
                  variant="outlined"
                  className="bg-white"
                  disabled={disabledTechnicianAndBooking}
                  label={'Select from dropdown to allocate a travel buffer'}
                  value={travelBufferTime}
                  onChange={(value: string) => {
                    const minutes =
                      value === TravelTimeBuffer.NONE
                        ? 0
                        : value === TravelTimeBuffer.MIN_15
                          ? 15
                          : value === TravelTimeBuffer.MIN_30
                            ? 30
                            : value === TravelTimeBuffer.MIN_45
                              ? 45
                              : 60;
                    setFieldValue('travelTimeBufferInMinutes', minutes);
                    setTravelBufferTime(value);
                  }}
                  onBlur={handleBlur}
                  error={
                    touched.travelTimeBufferInMinutes &&
                    Boolean(!values?.travelTimeBufferInMinutes) &&
                    Boolean(errors.travelTimeBufferInMinutes)
                  }
                >
                  {travelBufferKeys.map(buffer => (
                    <Option key={buffer} value={TravelTimeBuffer[buffer]}>
                      {TravelTimeBuffer[buffer]}
                    </Option>
                  ))}
                </Select>
              </div>
            ) : null}
            {bookViaCalendar && (
              <div className="flex pt-4">
                <Button onClick={() => setShowBookOnCalendar(true)} disabled={disabledTechnicianAndBooking}>
                  <div className="flex items-center gap-x-3">
                    <CalendarIcon
                      data-autoid={'btnBookOnCalendar'}
                      className="h-[22px] w-[22px] fill-current !text-white"
                    />{' '}
                    Book on Calendar
                  </div>
                </Button>
              </div>
            )}
          </div>
          {(!values?.bookings?.length || !activeBookings?.length) && Boolean(errors?.bookings) && (
            <Typography variant="small" className="mt-[-8px] text-error">
              {errors?.bookings?.toString()}
            </Typography>
          )}

          {(values?.status === ServiceJobStatus.Completed || disabled) && values?.signature && (
            <div className=" flex flex-col !gap-3 ">
              <Typography variant="h3">Customer Signature</Typography>
              <div className="rounded-lg border border-gray-300 pt-8">
                <div className="flex justify-center border-b border-dashed border-[#666666]">
                  <img
                    src={values?.signature?.uri || 'https://i.ibb.co/ZVGd8Qr/signature-1.png'}
                    alt={values?.signature?.caption || 'Customer Signature'}
                    className="-mb-6 h-auto w-64"
                  />
                </div>
                <div className="flex justify-end p-3">
                  <Typography
                    variant="paragraph"
                    className="rounded-md bg-[#ECECEC] px-6 py-2 text-center text-[#999999]"
                  >
                    {values?.signature?.note || 'Customer name'}
                  </Typography>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      {showLocationModal && (
        <SelectShippingAddressDialog
          addressType={addressType}
          values={{
            ShippingAddressID: shippingAddress?.ID,
            BillingAddressID: billingAddress?.ID,
          }}
          open={showLocationModal}
          onClose={() => setShowLocationModal(false)}
          customerDetails={customerDetails}
          handleUpdateOpCustomer={handleUpdateOpCustomer}
          isServiceModule={true}
        />
      )}
      {showAssetModal && (
        <SelectAssetDialog
          open={showAssetModal}
          handleCancel={() => {
            setShowAssetModal(!showAssetModal);
          }}
          handleNewAsset={() => {
            setShowAssetModal(false);
            setIsEditingAsset(false);
            setCreateNewAssetDialog(true);
            setLocations(initialAssetValues);
            setCollapseAsset(false);
          }}
          handleEdit={(selectedAsset: ICustomerAsset) => {
            setShowAssetModal(false);
            setCreateNewAssetDialog(true);
            setSelectedAsset(selectedAsset);
            const loc: ILocation = {
              Addresses: [
                {
                  Country: selectedAsset?.businessLocationAddress?.country,
                  State: selectedAsset?.businessLocationAddress?.state,
                  StreetAddress: selectedAsset?.businessLocationAddress?.streetAddress,
                  City: selectedAsset?.businessLocationAddress?.city,
                  PostCode: selectedAsset?.businessLocationAddress?.postCode,
                },
              ],
            };
            setLocations([loc]);
            setIsEditingAsset(true);
            setCollapseAsset(true);
          }}
          handleOnSave={(selectedAsset: ICustomerAsset | undefined) => {
            setShowAssetModal(false);
            setSelectedAsset(selectedAsset);
            setFieldValue('assetID', selectedAsset?.assetID);
          }}
          customerID={values?.customerID!}
          selectedAssetCustomer={selectedAsset}
        />
      )}
      {createNewAssetDialog && (
        <AddressDialog
          dialogTitle={isEditingAsset ? 'Edit asset' : 'Create new asset'}
          placeholder="Search asset"
          label="Search asset"
          isServiceAssetEdit={isEditingAsset}
          open={createNewAssetDialog}
          setOpen={() => {
            setCreateNewAssetDialog(createNewAssetDialog);
          }}
          locationIndex={0}
          addressIndex={0}
          locations={locations}
          setLocations={setLocations}
          isServiceAsset={true}
          handleUpdateOpCustomer={createAsset}
          onCopyShippingAddress={onCopyShippingAddress}
          closeDialog={() => {
            if (values?.assetID) {
              setShowAssetModal(true);
            }
            setCreateNewAssetDialog(false);
            setCollapseAsset(collapseAsset);
          }}
          collapseAsset={collapseAsset}
          setCollapseAsset={setCollapseAsset}
        />
      )}
      {showBookOnCalendar && (
        <BookOnCalendar
          onClose={() => setShowBookOnCalendar(false)}
          alreadySelectedTechnicians={values?.technicians}
          technicians={technicians}
          onScheduleJob={async jobInfo => {
            const booking = values?.bookings;

            const newBooking = {
              endDateTime_utc: moment(`${jobInfo.endDate}`).utc().format('YYYY-MM-DD HH:mm'),
              endTime: moment(jobInfo.endDate).format('HH:mm'),
              isActive: true,
              scheduleDate: moment(jobInfo.startDate).format('YYYY-MM-DD'),
              startTime: moment(jobInfo.startDate).format('HH:mm'),
              startDateTime_utc: moment(`${jobInfo.startDate}`).utc().format('YYYY-MM-DD HH:mm'),
            };

            booking?.push(newBooking);

            setActiveBookings(prevBookings => {
              return [
                ...(prevBookings ?? []),
                {
                  ...newBooking,
                  index: prevBookings?.length ?? 0,
                },
              ];
            });

            if (jobInfo.technician.id !== '-1') {
              const tech = selectedTechnicians.find(tech => tech.user.technicianUserID === +jobInfo.technician.id);
              if (!tech?.isSelected) {
                await onMultiSelectChange(jobInfo.technician.id);
              }
            }
            setFieldValue('bookings', booking);
            setShowBookOnCalendar(false);
          }}
        />
      )}
    </form>
  );
}

type ISchedulerDialogState = {
  startDate: Date;
  endDate: Date;
  technician: {
    id: string;
    name: string;
  };
};

interface BookOnCalendarProps {
  onClose: () => void;
  alreadySelectedTechnicians?: IServiceJobTechnician[];
  technicians: ITechnician[];
  onScheduleJob: (jobInfo: ISchedulerDialogState) => void;
}

const BookOnCalendar = ({onClose, alreadySelectedTechnicians, technicians, onScheduleJob}: BookOnCalendarProps) => {
  const {serviceJob} = useServiceContext();

  const alreadySelectedTechnicianIDs = useMemo(() => {
    const alreadySelected = alreadySelectedTechnicians?.filter(tech => tech.action !== Actions.remove) ?? [];
    if (alreadySelected.length) {
      return alreadySelected.map(tech => tech.technicianUserID!);
    } else {
      return technicians.map(tech => tech.userID!);
    }
  }, [alreadySelectedTechnicians]);

  technicians = useMemo(() => {
    return _.sortBy(technicians, [obj => (obj.userID === -1 ? 0 : 1), 'firstName']);
  }, [technicians]);

  return (
    <BookOnCalendarDialogContextProvider
      alreadySelectedTechnicians={alreadySelectedTechnicianIDs}
      technicians={technicians}
    >
      <AlertDialogSlideV2
        headingClassess="justify-between"
        title={serviceJob?.customerName!}
        dialogBodyClassess="py-4 min-h-[70vh] text-black-800"
        dialogClassess="!mx-5 !w-full !max-w-[96%] xl:!max-w-[1245px]"
        dialogActionsAlignment="justify-start"
        actions={[
          {
            label: 'Go Back',
            variant: 'outlined',
            action: onClose,
          },
        ]}
        headerChildren={<DialogHeaderFilter />}
      >
        <BookOnCalendarDialogBody onScheduleJob={onScheduleJob} />
      </AlertDialogSlideV2>
    </BookOnCalendarDialogContextProvider>
  );
};

const DialogHeaderFilter = () => {
  const {
    technicianList,
    isShowUnassignedServiceJobs,
    setIsShowUnassignedServiceJobs,
    selectedTechnicians,
    setSelectedTechnicians,
  } = useBookOnCalendarDialogContext();

  const handleResetFilter = () => {
    if (!isShowUnassignedServiceJobs) {
      setIsShowUnassignedServiceJobs(true);
    }
    setSelectedTechnicians(technicianList.map(tech => tech.userID!));
  };

  const isFilterApplied = useMemo(() => {
    if (!isShowUnassignedServiceJobs) {
      return true;
    }
    if (selectedTechnicians.length === technicianList.length) {
      return false;
    }
    return true;
  }, [isShowUnassignedServiceJobs, selectedTechnicians, technicianList]);

  return (
    <div className="flex gap-x-2">
      <FilterTechnician
        technicians={technicianList}
        setSelectedTechnicians={setSelectedTechnicians}
        selectedTechnicians={selectedTechnicians}
        isShowUnassignedServiceJobs={isShowUnassignedServiceJobs}
        setIsShowUnassignedServiceJobs={setIsShowUnassignedServiceJobs}
      />
      {isFilterApplied && (
        <Button variant="outlined" onClick={handleResetFilter}>
          Reset
        </Button>
      )}
    </div>
  );
};

interface BookOnCalendarDialogBodyProps {
  onScheduleJob: (jobInfo: ISchedulerDialogState) => void;
}

const BookOnCalendarDialogBody = ({onScheduleJob}: BookOnCalendarDialogBodyProps) => {
  const {
    activeView,
    calendarEvents,
    fetchBookingDiaryMonthCalendar,
    fetchBookingDiaryScheduledJobs,
    isLoading,
    isShowUnassignedServiceJobs,
    scheduledJobs,
    selectedDate,
    selectedTechnicians,
    setActiveView,
    setIsShowUnassignedServiceJobs,
    setSelectedDate,
    setSelectedTechnicians,
    technicianList,
  } = useBookOnCalendarDialogContext();

  const isActiveView = (currentView: currentActiveView, view: currentActiveView) => {
    if (currentView === view) {
      return true;
    }
    return false;
  };

  const onSwitchClick = (view: currentActiveView) => {
    if (view === 'scheduler') {
      fetchBookingDiaryScheduledJobs(selectedDate, selectedDate, selectedTechnicians);
    }
    setActiveView(view);
  };

  const onWeekChange = (startDate: Date, endDate: Date) => {
    fetchBookingDiaryScheduledJobs(startDate, endDate, selectedTechnicians);
  };

  const onDateSelect = async (date: Date) => {
    setSelectedDate(date);
    if (activeView === 'calendar') {
      setActiveView('scheduler');
    }
    fetchBookingDiaryScheduledJobs(date, date, selectedTechnicians);
  };

  const onMonthChange = (startDate: Date, endDate: Date) => {
    fetchBookingDiaryMonthCalendar(startDate, endDate, selectedTechnicians);
  };

  const EventView = ({event}: {event: (typeof calendarEvents)[0]}) => {
    return <Chip color="warning" size="lg" value={event.title} className="bg-warning/20" />;
  };

  const onClickDate = (date: Date) => {
    setActiveView('scheduler');
    setSelectedDate(date);
  };

  const onClickDateWithTechnician = (technicianID: number, date: Date) => {
    if (technicianID === -1) {
      setSelectedTechnicians([]);
    } else {
      setIsShowUnassignedServiceJobs(false);
      setSelectedTechnicians([technicianID]);
    }
    setSelectedDate(date);
    setActiveView('scheduler');
  };

  return (
    <LoadingOverlayV1 isLoading={isLoading}>
      {isActiveView(activeView, 'weekCalendar') && (
        <BookingDiaryWeekView
          isShowUnassignedServiceJobs={isShowUnassignedServiceJobs}
          onSwitchClick={onSwitchClick}
          onWeekChange={onWeekChange}
          schedulerData={scheduledJobs}
          selectedDate={selectedDate}
          selectedTechnicianIDs={selectedTechnicians}
          technicians={technicianList}
          onClickDate={onClickDate}
          onClickDateWithTechnician={onClickDateWithTechnician}
        />
      )}

      {isActiveView(activeView, 'calendar') && (
        <Calendar
          activeMonth={selectedDate}
          onDateSelect={onDateSelect}
          events={calendarEvents as []}
          eventViewComponent={EventView}
          isShowSwitcher={true}
          currentActiveView={activeView}
          onSwitchClick={onSwitchClick}
          rootContainerProps={{className: 'font-poppins'}}
          headerProps={{
            className: 'text-black-800 font-medium text-lg',
          }}
          containerProps={{className: 'overflow-y-auto h-[calc(100vh-259px)] font-poppins'}}
          onMonthChange={onMonthChange}
        />
      )}
      {isActiveView(activeView, 'scheduler') && (
        <SchedulerDialog
          isShowUnassignedServiceJobs={isShowUnassignedServiceJobs}
          selectedTechnicians={selectedTechnicians}
          onScheduleJob={onScheduleJob}
          selectedDate={selectedDate}
          schedulerData={scheduledJobs}
          technicians={technicianList}
          onSwitchClick={onSwitchClick}
          setSelectedDate={onDateSelect}
        />
      )}
    </LoadingOverlayV1>
  );
};
