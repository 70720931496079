import React from 'react';

export function IconEnvelope(props: SVGProps) {
    return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={12}
          height={12}
          fill="none"
          {...props}
        >
          <path
            fill="#CCC"
            fillRule="evenodd"
            d="M11.23 4.045L7.766.727A2.513 2.513 0 004.24.719L.77 4.045A2.514 2.514 0 000 5.849v3.646a2.503 2.503 0 002.5 2.5h7a2.503 2.503 0 002.5-2.5V5.849a2.512 2.512 0 00-.77-1.804zM4.94 1.434a1.507 1.507 0 012.128.008l3.374 3.232-3.381 3.382a1.537 1.537 0 01-2.121 0L1.558 4.674l3.382-3.24zM11 9.494a1.5 1.5 0 01-1.5 1.5h-7a1.5 1.5 0 01-1.5-1.5V5.85a1.506 1.506 0 01.03-.29l3.202 3.204c.98.97 2.556.97 3.535 0L10.97 5.56c.02.095.03.192.03.289v3.646z"
            clipRule="evenodd"
          />
        </svg>
      )
}
