import React, {useEffect, useState} from 'react';
import {ARDialogTemplate} from '../ARDialogTemplate';
import {IGetInventoryTemplates} from '../../../model/OnboardingV1Step';
import InventorySelectableButtons from './InventorySelectableButtons';
import {ISelectedInventoryValue} from '../../lowTouchOnboarding/SetUpInventory';
import PSBLOnboardingFooter from '../../buttons/PSBLOnboardingFooter';

interface IGetInventoryItemsSelection {
  inventoryData?: IGetInventoryTemplates;
  selectedTitles: ISelectedInventoryValue[];
  onClose: () => void;
  setSelectedTitles: React.Dispatch<React.SetStateAction<ISelectedInventoryValue[]>>;
}

export const InventoryItemsSelectionModal = (props: IGetInventoryItemsSelection) => {
  // Props
  const {selectedTitles, inventoryData, onClose, setSelectedTitles} = props;

  // State
  const [localSelectedTitles, setLocalSelectedTitles] = useState<ISelectedInventoryValue[]>(selectedTitles);

  // useEffect
  useEffect(() => {
    setLocalSelectedTitles(selectedTitles);
  }, [selectedTitles]);

  // Functions
  const handleSave = () => {
    setSelectedTitles(localSelectedTitles);
    onClose();
  };

  return (
    <>
      <ARDialogTemplate
        dialogProps={{size: 'md', open: true, className: 'sm:!w-[791px] sm:!min-w-[791px]'}}
        isFullScreen
        header={<p className="font-poppins text-xl font-light text-[#333333]">Inventory items selection</p>}
        bodyClass="relative !px-0"
        body={
          <div className="sm:max-h-auto flex h-full max-h-[400px] flex-col items-center overflow-auto pb-20 sm:!min-w-[791px] sm:justify-center">
            <InventorySelectableButtons
              selectedTitles={localSelectedTitles}
              inventoryData={inventoryData}
              setSelectedTitles={setLocalSelectedTitles}
            />
            <PSBLOnboardingFooter
              isCancel
              hideBookOnboardingButton
              onCancelClick={onClose}
              onPrimaryClick={handleSave}
              primaryLabel="Save & close"
            />
          </div>
        }
      />
    </>
  );
};

export default InventoryItemsSelectionModal;
