import React, {useCallback, useContext, useEffect, useMemo, useState} from 'react';
import {Button, Typography, useTabs} from 'spenda-ui-react';
import {IntegrationContext} from '../../context/IntegrationContext';
import {IAccounts} from '../../model/accounts/Accounts';
import {
  ChartsOfAccountClass,
  ChartsOfAccountTaxType,
  ChartsOfAccountType,
  DatTypes,
  LowTouchPsblOnboardingSteps,
} from '../../model/constants/Constants';
import {IPrefixSuffix, useAPConfigs} from '../../services/useAPConfigs';
import useAccountAPI from '../../services/useAccountAPI';
import PSBLOnboardingFooter, {ResponsiveBookOnboardingButton} from '../buttons/PSBLOnboardingFooter';
import CreateClearingAccountInputComponent from './CreateAccountInput';
import {SelectCOADropdown} from '../AccountsReceivable/required-attention/SelectCOADropdown';
import {ImportWorkflowsModal} from '../AccountsReceivable/modals/ImportWorkflowsModal';
import {usePSBLHook} from '../../hooks/useARHook';
import {defaultMarketPlacePath} from '../../config';
import {useOnboarding} from '../../services/useOnboarding';
import IconTipBulb from '../../assets/svg/IconTipBulb';

interface IClearingAccountSetupProps {
  handleNext: () => void;
  currentActiveTab: LowTouchPsblOnboardingSteps;
  startSuppliersWorkflow: () => Promise<void>;
}

export const CreateClearingAccount = (props: IClearingAccountSetupProps) => {
  // Props
  const {currentActiveTab, handleNext, startSuppliersWorkflow} = props;

  // State
  const [isShowClearingAccountsScreen, setIsShowClearingAccountsScreen] = useState(false);

  // Hooks
  const {handleTabChange} = useOnboarding();

  useEffect(() => {
    if (currentActiveTab === LowTouchPsblOnboardingSteps.ClearingAccountSetup) {
      setIsShowClearingAccountsScreen(true);
    }
  }, [currentActiveTab]);

  // Functions
  const handleChartOfAccountsImportComplete = async () => {
    try {
      setIsShowClearingAccountsScreen(true);
      await handleTabChange({
        isPsblOnboarding: true,
        action: 'next',
        completeSteps: [LowTouchPsblOnboardingSteps.ChartOfAccountsWorkflow],
        nextStep: LowTouchPsblOnboardingSteps.ClearingAccountSetup,
      });
      startSuppliersWorkflow();
    } catch {}
  };

  return (
    <div className="wrapper relative h-full w-full overflow-hidden bg-white">
      {isShowClearingAccountsScreen ? (
        <CreateAccounts handleComplete={handleNext} />
      ) : (
        <ImportChartsOfAccounts handleComplete={handleChartOfAccountsImportComplete} />
      )}
    </div>
  );
};

interface IImportCOAProps {
  handleComplete: () => void;
}
const ImportChartsOfAccounts = (props: IImportCOAProps) => {
  // Props
  const {handleComplete} = props;

  // State
  const [showFetchCOAModal, setShowFetchCOAModal] = useState(false);
  const {state: tabState} = useTabs();

  // Context
  const {financialAdaptor} = useContext(IntegrationContext);

  useEffect(() => {
    if (tabState?.active === LowTouchPsblOnboardingSteps.ClearingAccountSetup) {
      setShowFetchCOAModal(true);
    }
  }, [tabState?.active]);

  // Functions
  const checkCOASuccess = () => {
    setShowFetchCOAModal(false);
    handleComplete();
  };

  const queuePayload = useMemo(
    () =>
      financialAdaptor && {
        Action: 'Read',
        AdaptorName: financialAdaptor.Name,
        AdaptorID: financialAdaptor.ID,
        DatTypeID: DatTypes.Accounts,
        MaxWaitSecs: 29,
      },
    [financialAdaptor],
  );

  return (
    <div className="wrapper relative h-full w-full overflow-hidden bg-white pb-16">
      <div className="mx-auto h-full w-full overflow-y-auto overflow-x-hidden pb-28 sm:max-w-[622px] sm:pb-0">
        <div className={`mx-auto mt-4 !max-w-[530px] align-middle`}>
          <p className={`text-left font-poppins text-[20px] font-medium text-[#333] sm:text-2xl sm:font-light`}>
            Let’s get your chart of accounts
          </p>
          <p className={`mt-6 text-left text-sm font-normal text-[#999999] sm:mt-2`}>
            This is the account that we’ll use to pay the invoices in your financial system. We recommend setting up a
            new one, so that you can easily manage the new process without any crossover with old transactions.
          </p>
        </div>
        <div className={`mx-auto mt-4 w-full align-middle sm:!max-w-[530px]`}>
          <Button
            className="my-10 w-full bg-white font-semibold text-primary sm:w-[299px]"
            variant="outlined"
            data-autoid={'btnImportChartsAccounts'}
            onClick={() => setShowFetchCOAModal(true)}
            disabled={!financialAdaptor}
            loading={!financialAdaptor}
          >
            Import Charts of Accounts
          </Button>
        </div>
        {showFetchCOAModal && (
          <ImportWorkflowsModal
            open={showFetchCOAModal}
            onSuccess={checkCOASuccess}
            message="We're currently retrieving your chart of accounts. This should only take a moment."
            queuePayload={queuePayload}
          />
        )}
        <ResponsiveBookOnboardingButton />
      </div>
      <PSBLOnboardingFooter isChatWidget onPrimaryClick={() => {}} isDisabled />
    </div>
  );
};

interface IClearingAccountProps {
  handleComplete: () => void;
}
const CreateAccounts = (props: IClearingAccountProps) => {
  // Props
  const {handleComplete} = props;

  // State
  const [clearingAccounts, setClearingAccounts] = useState<IAccounts[]>([]);
  const [roundingAccountCode, setRoundingAccountCode] = useState<string>();
  const [codes, setCodes] = useState({clearingAccount: '', roundingExpense: '', roundingIncome: ''});
  const [error, setError] = useState({clearingAccount: '', roundingExpense: '', roundingIncome: ''});
  const [isSaveLoading, setIsSaveLoading] = useState<boolean>(false);
  const [isAccountCreated, setIsAccountCreated] = useState({
    clearingAccount: false,
    roundingExpense: false,
    roundingIncome: false,
  });

  // APIs
  const {getCreditlineAccounts} = useAccountAPI();
  const {savePrefixSuffix} = useAPConfigs();

  // Context
  const {isXeroAdaptor, isMYOBAdaptor} = useContext(IntegrationContext);

  // Hooks
  const {getSupplierByMarketplacePath} = usePSBLHook();
  const supplierName = getSupplierByMarketplacePath(defaultMarketPlacePath)?.SupplierName;

  // UseEffect
  useEffect(() => {
    if (isXeroAdaptor || isMYOBAdaptor) getClearingAccounts();
  }, [isXeroAdaptor, isMYOBAdaptor]);

  // Functions
  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const {value, name} = e.target;
      if (!value) {
        setError(p => ({...p, [name]: 'Please enter a code.'}));
      } else if (clearingAccounts?.find(c => c?.Code === value)) {
        setError(p => ({...p, [name]: 'This code already exists. Please enter a new code.'}));
      } else {
        setError(p => ({...p, [name]: ''}));
      }
      setCodes(p => ({...p, [name]: value}));
    },
    [clearingAccounts],
  );

  const getClearingAccounts = async () => {
    try {
      await getCreditlineAccounts().then((data: IAccounts[]) => {
        const c = data.map(account => account.Code);
        if (isXeroAdaptor) {
          const isXeroDeaultRoundingCodeAvl = data?.some(a => a?.Code === '860');
          isXeroDeaultRoundingCodeAvl && setRoundingAccountCode('860');
          const missingCode = getMissingCode(c, 800, 899);
          setCodes(p => ({...p, clearingAccount: missingCode}));
        } else if (isMYOBAdaptor) {
          const clearingAccount = getMissingCode(c, 2100, 2199, '2');
          const roundingIncome = getMissingCode(c, 1100, 1199, '8');
          const roundingExpense = getMissingCode(c, 1100, 1199, '9');
          setCodes({clearingAccount, roundingExpense, roundingIncome});
        }
        setClearingAccounts(data);
      });
    } catch {}
  };

  const getMissingCode = (codes: string[], startFrom: number, end: number, prefix?: string) => {
    for (let i = startFrom; i <= end; i++) {
      const codeToCompare = prefix ? `${prefix}-${i}` : `${i}`;
      if (!codes.includes(codeToCompare)) {
        return codeToCompare;
      }
    }
    return '';
  };

  const handleSubmit = async () => {
    try {
      setIsSaveLoading(true);
      let payload: Partial<IPrefixSuffix> = {
        roundingAccountCode: isMYOBAdaptor ? codes?.roundingExpense : roundingAccountCode,
        roundingRevenueAccountCode: isMYOBAdaptor ? codes?.roundingIncome : undefined,
      };
      await savePrefixSuffix(payload);
      setIsSaveLoading(false);
      handleComplete();
    } catch {}
  };

  const isNextButtonDisable = useMemo(() => {
    if (isXeroAdaptor) {
      return Boolean(
        !codes?.clearingAccount || !roundingAccountCode || error?.clearingAccount || !isAccountCreated?.clearingAccount,
      );
    } else if (isMYOBAdaptor) {
      return (
        Object.values(codes).some(code => !code) ||
        Object.values(error).some(e => e) ||
        Object.values(isAccountCreated).some(val => !val)
      );
    }
    return true;
  }, [codes, isXeroAdaptor, isMYOBAdaptor, isAccountCreated]);

  const handleIsAccountCreated = (newState: Partial<typeof isAccountCreated>) =>
    setIsAccountCreated(prevState => ({
      ...prevState,
      ...newState,
    }));

  return (
    <div className="relative h-full w-full overflow-hidden bg-white pb-24">
      <div className="mx-auto flex h-full w-full flex-col justify-between overflow-y-auto overflow-x-hidden sm:max-w-[622px] sm:pb-0">
        <div>
          <div className={`mx-auto mt-4 !max-w-[622px] align-middle`}>
            <p className={`text-left font-poppins text-[20px] font-medium text-[#333] sm:text-2xl sm:font-light`}>
              Let’s get your clearing account set up
            </p>
            <p className={`mt-6 text-left text-sm font-normal text-[#999999] sm:mt-2`}>
              We'll create a liability account in your financial system that will record credits and debits for each
              transaction on your Capricorn statement. Starting with a fresh account means an easy transition to your
              integrated statement and avoids crossover with any old transactions. We recommend having your bookkeeper
              review this before proceeding.
            </p>
          </div>
          <div className={`mx-auto mt-4 !max-w-[622px] align-middle`}>
            <CreateClearingAccountInputComponent
              onChange={e => onChange(e)}
              name="clearingAccount"
              error={error?.clearingAccount}
              code={codes?.clearingAccount}
              connectedSupplierName={supplierName}
              description="Current Statement"
              accountType={ChartsOfAccountType.CurrentLiability}
              accountTaxType={ChartsOfAccountTaxType.BasExcluded}
              accountClass={ChartsOfAccountClass.Liability}
              isEnablePayments={true}
              handleIsAccountCreated={handleIsAccountCreated}
            />
            <div className="mt-5 flex flex-row items-center rounded-[6px] bg-[#f6f6f6] p-2.5 text-xs font-normal text-black-800 sm:mt-8">
              <IconTipBulb className="mr-2.5 !h-[29px] !w-[22px]" />
              If you need assistance with reconciling your statements, click the 'Live Chat' option below to schedule a
              session with our Customer Success team.
            </div>
            <div className="mt-7 flex flex-col sm:gap-2">
              <Typography className="mt-4 font-poppins font-medium text-[#000000]" variant="paragraph">
                Rounding Tolerance
              </Typography>
              {isMYOBAdaptor ? (
                <>
                  <Typography className="flex-start flex text-sm font-normal text-[#333333]">
                    We'll create rounding accounts in your financial system that will record credits and debits for each
                    Tax rounding errors on your Capricorn statement.
                  </Typography>
                  <CreateClearingAccountInputComponent
                    onChange={e => onChange(e)}
                    name="roundingExpense"
                    error={error?.roundingExpense}
                    code={codes?.roundingExpense}
                    connectedSupplierName={supplierName}
                    description="Rounding Expense"
                    accountType={ChartsOfAccountType.Expense}
                    accountTaxType={ChartsOfAccountTaxType.BasExcluded}
                    accountClass={ChartsOfAccountClass.OtherExpense}
                    isEnablePayments={false}
                    handleIsAccountCreated={handleIsAccountCreated}
                  />
                  <CreateClearingAccountInputComponent
                    onChange={e => onChange(e)}
                    name="roundingIncome"
                    error={error?.roundingIncome}
                    code={codes?.roundingIncome}
                    connectedSupplierName={supplierName}
                    description="Rounding Income"
                    accountType={ChartsOfAccountType.OtherIncome}
                    accountTaxType={ChartsOfAccountTaxType.BasExcluded}
                    accountClass={ChartsOfAccountClass.OtherIncome}
                    isEnablePayments={false}
                    handleIsAccountCreated={handleIsAccountCreated}
                  />
                </>
              ) : (
                <div className="sm:mb-2">
                  <Typography className="flex-start flex text-sm font-normal text-[#333333]">
                    How would you like us to post tax rounding adjustments?
                  </Typography>
                  <div className="mt-3 flex !h-[60px] w-full flex-col gap-6 pb-2 sm:!w-[330px]">
                    <SelectCOADropdown
                      isUsingForRoundingAdjustment
                      key={'roundingAccountCode'}
                      value={roundingAccountCode}
                      onChange={value => setRoundingAccountCode(value)}
                      name={'roundingAccountCode'}
                      color="primary"
                      size="lg"
                      variant="outlined"
                      label="Select an account"
                      placeholder="Select an account"
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <ResponsiveBookOnboardingButton />
      </div>
      <PSBLOnboardingFooter
        isChatWidget
        isDisabled={isNextButtonDisable}
        onPrimaryClick={() => handleSubmit()}
        isLoading={isSaveLoading}
      />
    </div>
  );
};
