import React from 'react';
import APSuppierAddIcon from '../../assets/svg/accounts-payable/APSupplierAddIcon';
import {IconButton, InputAdornment, makeStyles} from '@material-ui/core';
import {DatTypes} from '../../model/constants/Constants';
import {ArRefreshBtn} from '../../components/arRefresh/ArRefresh';
import {Form, Formik} from 'formik';
import {STextField} from '../../components/inputs/STextField';
import LoadingIndicator from '../../components/ui/LoadingIndicator';
import ClearOutlinedIcon from '@material-ui/icons/ClearOutlined';
import APSupplierSearchIcon from '../../assets/png/AccountsPayable/APSupplierSearchIcon.png';
import {useHistory} from 'react-router-dom';
import {AUTH_BUYING_NEW_SUPPLIER_ROUTE} from '../../routes/SupplierManagementRoutes';
import moment from 'moment';

interface ISuppliersListFilterProps {
  refetchSuppliers: any;
  setSearchString: any;
  searchText?: string;
  isLoading: boolean;
}

const useSupplierListHeaderStyle = makeStyles({
  batchSelect: {
    minWidth: '185px',
    height: '40px',
    border: '1px solid #333',
    borderRadius: '6px',
    marginLeft: '10px',
    '& .MuiSelect-select': {
      color: '#333333',
      fontSize: '14px',
      fontWeight: '600',
      fontFamily: 'poppins',
      lineHeight: '16px',
      padding: '0px 40px 0px 10px',
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      height: '40px',
      '& .MuiRadio-root': {
        display: 'none',
      },
    },
    '& svg': {
      color: '#1c78ad',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: '0px!important',
    },
  },
  searchFrom: {
    '& .MuiFormControl-root': {
      width: '100%',
      '& .MuiInputBase-root': {
        height: '40px',
        paddingRight: '0px',
      },
      '& .MuiInputAdornment-positionStart': {
        position: 'absolute',
        right: '0px',
        zIndex: 9,
        width: '40px',
        maxHeight: '40px',
        height: '40px',
        margin: 0,
        '& img': {
          borderRadius: '0px 6px 6px 0px',
        },
      },
      '& .MuiInputAdornment-positionEnd': {
        marginRight: '30px',
      },
      '& fieldset.MuiOutlinedInput-notchedOutline': {
        border: '1px solid #1c78ad!important',
      },
    },
  },
});

export const SuppliersListHeader = (props: ISuppliersListFilterProps) => {
  const {refetchSuppliers, setSearchString, searchText, isLoading} = props;

  const classes = useSupplierListHeaderStyle();
  const history = useHistory();

  // Functions
  const handleRefreshData = () => {
    refetchSuppliers();
  };

  const onSubmitSearch = (values: {searchText: string}): Promise<void> => {
    setSearchString(values.searchText);
    return Promise.resolve();
  };

  return (
    <div className="mb-7 flex items-center justify-between border-[#D8D8D8] border-b-default pb-2.5">
      <div className="flex">
        <Formik initialValues={{searchText: searchText as string}} onSubmit={onSubmitSearch} validateOnChange={false}>
          {formik => (
            <Form className={`mr-5 w-full min-w-[350px] ${classes.searchFrom}`}>
              <STextField
                v2
                name="searchText"
                id="searchText"
                value={formik?.values?.searchText}
                placeholder={'Search by name, phone or address'}
                {...formik}
                onChange={e => {
                  formik.setFieldValue('searchText', e?.target?.value);
                }}
                autoComplete="off"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <img
                        data-autoid={'imgSupplierSearchIcon'}
                        className="cursor-pointer"
                        src={APSupplierSearchIcon}
                        width={'40px'}
                        height="45px"
                        onClick={formik.submitForm}
                      />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <LoadingIndicator isLoading={isLoading} size="sm" color="#BBB" position={{right: '50px'}} />
                      {formik.values.searchText && !isLoading && (
                        <IconButton
                          data-autoid={'lnkSearchtext'}
                          type="button"
                          id="clear-btn-input"
                          aria-label="clear text string"
                          className={`absolute right-[.25em] text-xs`}
                          onClick={() => {
                            formik.setFieldValue('searchText', '');
                            onSubmitSearch({searchText: ''});
                          }}
                        >
                          <ClearOutlinedIcon />
                        </IconButton>
                      )}
                    </InputAdornment>
                  ),
                }}
              />
            </Form>
          )}
        </Formik>
        <div className="min-w-[40px] cursor-pointer">
          <APSuppierAddIcon
            width="40px"
            height={'40px'}
            data-autoid="lnkAddSupplier"
            onClick={() => {
              history.push(`${AUTH_BUYING_NEW_SUPPLIER_ROUTE}`);
            }}
          />
        </div>
      </div>
      <div className="flex">
        <ArRefreshBtn
          handleRefreshData={handleRefreshData}
          datTypeId={DatTypes.Suppliers}
          title={'Refresh suppliers'}
          lastExecDateTimeOverride={moment().subtract(3, 'years').toISOString()}
        />
      </div>
    </div>
  );
};
