import React, {useContext, useEffect, useState} from 'react';
import {SModal} from '../modals/modalSpendaMeterialUI';
import {Box, FormControlLabel, MenuItem, Radio, RadioGroup, Select, makeStyles} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import {IntegrationContext} from '../../context/IntegrationContext';
import {APFooter} from './APFooter';
import blueInfoIcon from '../../assets/svg/accounts-payable/blue-info-icon.svg';
import APTooltip from '../data-display/APTootip';
import {ArRefreshBtn} from '../arRefresh/ArRefresh';
import {DatTypes} from '../../model/constants/Constants';
import useAccountAPI from '../../services/useAccountAPI';
import {IAccounts} from '../../model/accounts/Accounts';
import {SecondaryButton} from '../buttons/DefaultButtons';
import Creditline from '../../assets/png/AccountsPayable/Creditline.png';
import {useStagedTransactionsAPI} from '../../services/useStagedTransactionsAPI';
import {Toast} from '../../utils/Toast';

const useCreditlineStyles = makeStyles({
  dropdown: {
    '&.MuiTableCell-body': {
      padding: '0px!important',
      '& .MuiOutlinedInput-root': {
        borderRadius: '10px',
        padding: '0px 10px',
        '& input': {
          fontFamily: 'poppins',
          fontSize: '14px',
          textAlign: 'right',
        },
      },
    },
    '& .MuiInputBase-root': {
      maxWidth: '600px',
      width: '224px',
      height: '40px',
      border: '1px solid',
      borderRadius: '6px',
      '&.Mui-focused': {
        border: '1px solid',
      },
      '&:before, &:after': {
        display: 'none',
      },
    },
    '& .MuiSelect-select': {
      color: '#333333',
      fontSize: '14px',
      lineHeight: '16px',
      fontWeight: '500',
      fontFamily: 'poppins',
      paddingLeft: '10px',
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      '& p': {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
      '&.Mui-disabled': {
        opacity: 0.4,
      },
    },
    '& svg': {
      color: '#1c78ad',
      width: '25px',
      height: '25px',
    },
    '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
      {
        border: '1px solid #333',
      },
    '& legend': {
      width: '89px',
    },
  },
  '& .menuItem': {
    overflow: 'visible!important',
    '& li': {
      color: '#333',
      fontSize: '14px',
      fontWeight: 600,
      fontFamily: 'poppins',
      textTransform: 'capitalize',
      justifyContent: 'flex-start!important',
      padding: '10px',
      '&:hover': {
        background: '#D3E5EF80',
      },
      '&.Mui-selected': {
        background: '#D3E5EF50',
      },
    },
  },
});

interface ICreditlineModal {
  handleClose?: () => void;
  selectedCreditLineAccountID?: number | null;
  supplierID?: number;
  fromSupplierDetails?: boolean;
  setCreditLineAccountID?: (value: number | null) => void;
  disableDoneButton?: boolean;
}

export const CreditlineModal = (props: ICreditlineModal) => {
  const {
    handleClose,
    selectedCreditLineAccountID,
    supplierID,
    fromSupplierDetails,
    setCreditLineAccountID,
    disableDoneButton,
  } = props;
  const classes = useCreditlineStyles();
  const [hasCreditlineAccount, setHasCreditlineAccount] = useState<boolean>(true);
  const {financialAdaptor} = useContext(IntegrationContext);

  const {getCreditlineAccounts} = useAccountAPI();
  const {postCreditLineAccount} = useStagedTransactionsAPI();
  const [clearingAccounts, setClearingAccounts] = useState<IAccounts[]>([]);
  const [selectedCreditline, setSelectedCreditline] = useState<number | undefined>(selectedCreditLineAccountID || -1);
  const [previewImage, setPreviewImage] = useState<boolean>(false);

  const getChartAccounts = () => {
    getCreditlineAccounts().then((data: IAccounts[]) => {
      setClearingAccounts(data);
    });
  };

  const handlePostCreditLineAccount = async () => {
    if (hasCreditlineAccount && selectedCreditline?.toString() === '-1') {
      Toast.info('Please select creditline account.');
      return;
    }
    if (hasCreditlineAccount && supplierID && selectedCreditline && selectedCreditline !== -1) {
      await postCreditLineAccount(supplierID, selectedCreditline);
    }
    handleClose?.();
  };

  useEffect(() => {
    if (fromSupplierDetails && selectedCreditline) {
      setCreditLineAccountID?.(selectedCreditline === -1 ? null : selectedCreditline);
    }
  }, [selectedCreditline]);

  useEffect(() => {
    getChartAccounts();
  }, []);

  const renderCreditlineComponent = (
    <div className={`spenda-color flex h-full max-w-full items-center justify-center font-poppins text-xl`}>
      <div
        className={`${
          !fromSupplierDetails ? 'modal-box h-[461px] max-w-[700px] px-5' : 'w-full pl-2'
        } pb-5 pt-3 text-spenda-primarytext`}
      >
        {!fromSupplierDetails && (
          <Box className={`-mx-5 flex justify-between border-[#CCCCCC] border-b-default px-3 pb-3`}>
            <h2 className={`w-full self-center text-center text-xl font-light`}>{`Creditline account`}</h2>
            <Box className="flex justify-end text-right">
              <span className="ap-onboarding-close-icon">
                <CloseIcon data-autoid="btnClose" onClick={handleClose} className="text-primary" />
              </span>
            </Box>
          </Box>
        )}
        <Box className={`mt-5 flex text-base font-normal`}>
          <div>
            <p className="font-normal">{`Do you already have a creditline account in ${financialAdaptor?.Name}?`}</p>
            <div className="mt-3 flex flex-row justify-start ">
              <RadioGroup className="flex !flex-row !flex-nowrap">
                <FormControlLabel
                  disabled={
                    selectedCreditLineAccountID &&
                    selectedCreditLineAccountID !== null &&
                    selectedCreditLineAccountID !== -1
                      ? true
                      : false
                  }
                  value="Yes"
                  control={
                    <Radio
                      onChange={() => {
                        setHasCreditlineAccount(true);
                      }}
                      data-autoid={`rbtnYes`}
                    />
                  }
                  label={<span className="ml-1 font-poppins text-base font-semibold">{`${`Yes`}`}</span>}
                  checked={hasCreditlineAccount}
                />
                <FormControlLabel
                  disabled={
                    selectedCreditLineAccountID &&
                    selectedCreditLineAccountID !== null &&
                    selectedCreditLineAccountID !== -1
                      ? true
                      : false
                  }
                  value="No"
                  control={
                    <Radio
                      onChange={() => {
                        setHasCreditlineAccount(false);
                      }}
                      data-autoid={`rbtnNo`}
                    />
                  }
                  className="!ml-6"
                  label={<span className="ml-1 font-poppins text-base font-semibold">{`${`No`}`}</span>}
                  checked={!hasCreditlineAccount}
                />
              </RadioGroup>
            </div>
          </div>
        </Box>
        {hasCreditlineAccount && (
          <Box
            className={`mt-2 flex flex-row text-xs font-normal ${
              fromSupplierDetails ? 'justify-between' : 'items-center'
            }`}
          >
            <div className="flex flex-row items-center">
              <span className="font-normal">{`Select your credit line:`}</span>{' '}
              <APTooltip arrow placement="bottom" title="">
                <img
                  className="ml-1 cursor-pointer"
                  src={blueInfoIcon}
                  data-autoid={`imgBlueInfo`}
                  height="16px"
                  width={'16px'}
                />
              </APTooltip>
            </div>
            <div className="flex flex-row items-center">
              <div className={`${classes.dropdown} ${!fromSupplierDetails ? 'mx-4' : 'mr-1'}  flex items-center `}>
                <Select
                  labelId="demo-simple-select-label"
                  disabled={
                    selectedCreditLineAccountID &&
                    selectedCreditLineAccountID !== null &&
                    selectedCreditLineAccountID !== -1
                      ? true
                      : false
                  }
                  id="demo-simple-select"
                  value={selectedCreditline}
                  style={{
                    color: 'black',
                  }}
                  onChange={event => setSelectedCreditline(event?.target?.value as number)}
                  inputProps={{
                    id: 'deviceSource-native-select',
                    'data-autoid': `ddlClearsTo`,
                    name: 'apClearsTo',
                  }}
                  MenuProps={{
                    getContentAnchorEl: null,
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'left',
                    },
                  }}
                  data-autoid={`selectClearTo`}
                >
                  <MenuItem value="-1">
                    <p className="menuItem opacity-50">Select creditline account</p>
                  </MenuItem>
                  {clearingAccounts.map(({Name, ID, Code}) => (
                    <MenuItem key={ID} value={ID}>
                      <p className="menuItem">
                        {Code} - {Name}
                      </p>
                    </MenuItem>
                  ))}
                </Select>
              </div>
              {(selectedCreditLineAccountID == null ||
                selectedCreditLineAccountID == -1 ||
                !selectedCreditLineAccountID) && (
                <ArRefreshBtn
                  handleRefreshData={getChartAccounts}
                  datTypeId={DatTypes.Accounts}
                  showOnlyRefreshIcon={fromSupplierDetails}
                  title={'Refresh Data'}
                />
              )}
            </div>
          </Box>
        )}
        {hasCreditlineAccount && (
          <span className="font-poppins text-xs font-normal text-spenda-error">
            This configuration must be reviewed by your accountant or bookeeper*
          </span>
        )}
        {!hasCreditlineAccount && (
          <>
            <div className="mt-2 h-[77px] w-[550px] rounded-[10px] bg-[#F4E8D1] px-[14px] py-2.5 font-poppins text-xs">
              <p className="font-medium">Why creditline is important?</p>
              <p className="font-norma mt-1">
                Creditline is important because Create New clearing account in XERO. Select Account type as liability
                account, Select Account type as liability account.
              </p>
            </div>
            <div className="mb-4 flex flex-row items-center justify-between">
              <div>
                <p className="pt-4 text-base font-medium text-[#666666]">Steps to setup creditline accout :</p>
                <ul>
                  <li className="items-cente flex justify-start pl-2 pt-3">
                    <p className="text-xs text-spenda-labeltext">&#8226; &nbsp;Create New clearing account in XERO</p>
                  </li>
                  <li className="flex items-center justify-start pl-2  pt-1">
                    <p className="text-xs text-spenda-labeltext">
                      &#8226; &nbsp;Select Account type as liability account
                    </p>
                  </li>
                  <li className="flex items-center justify-start pl-2 pt-1">
                    <p className="text-xs text-spenda-labeltext"> &#8226; &nbsp;Set tax as BAS excluded</p>
                  </li>
                  <li className="flex items-center justify-start pl-2 pt-1">
                    <p className="text-xs text-spenda-labeltext">
                      &#8226; &nbsp;Select option "Enable payment to this account"
                    </p>
                  </li>
                  <li className="flex items-center justify-start pl-2 pt-1">
                    <p className="text-xs text-spenda-labeltext">
                      &#8226; &nbsp;Once setup is done in XERO, select your creditline account
                    </p>
                  </li>
                </ul>
              </div>

              <div className="mr-4">
                <img src={Creditline} alt="" width="176px" height="130px" />
                <span
                  className="mt-1 flex cursor-pointer items-center justify-center text-xs font-semibold text-primary"
                  onClick={() => setPreviewImage(true)}
                >
                  Preview
                </span>
              </div>
            </div>
            <SecondaryButton
              label="I Have Setup My Creditline Account"
              onClick={() => setHasCreditlineAccount(true)}
              data-autoid="btnHasCreditlineSetup"
            />
          </>
        )}
        {!fromSupplierDetails && (
          <APFooter
            isCancelButton={true}
            buttonLabel={'Done'}
            isDisabled={disableDoneButton}
            onNextClick={() => {
              if (
                (!selectedCreditLineAccountID ||
                  selectedCreditLineAccountID !== null ||
                  selectedCreditLineAccountID !== -1) &&
                !selectedCreditline
              ) {
                handleClose?.();
              } else handlePostCreditLineAccount();
            }}
            handleCancelClick={handleClose}
            isT2TSupplierModule={true}
            conditionallyClass={true}
            APFooterClass="!bottom-[10px] !left-[10px]"
            style={{width: 'calc(100% - 20px)'}}
          />
        )}
      </div>
    </div>
  );

  return (
    <>
      {previewImage && (
        <SModal open onClose={() => setPreviewImage(false)}>
          <div className="flex items-center justify-center" onClick={() => setPreviewImage(false)}>
            <CloseIcon
              data-autoid="btnClose"
              onClick={() => setPreviewImage(false)}
              className="absolute right-4 top-4 text-primary"
            />
            <img src={Creditline} style={{height: '100vh'}} />
          </div>
        </SModal>
      )}
      {!fromSupplierDetails ? <SModal open>{renderCreditlineComponent}</SModal> : renderCreditlineComponent}
    </>
  );
};
