import {useEffect, useState} from 'react';
import {ISchedulerData, ITechnician, ITechnicianViewJobs} from '../model/service-management/serviceJob';
import _ from 'lodash';
import moment from 'moment';

interface SchedulerJobProps {
  selectedTechnicianIDs: number[];
  technicians: ITechnician[];
  schedulerData: ITechnicianViewJobs[];
  isShowUnassignedServiceJobs?: boolean;
}

const useScheduleJob = ({
  schedulerData,
  selectedTechnicianIDs,
  technicians,
  isShowUnassignedServiceJobs,
}: SchedulerJobProps) => {
  const [techniciansList, setTechniciansList] = useState<{id: string; name: string}[]>([]);
  const [data, setData] = useState<ISchedulerData[]>([]);

  useEffect(() => {
    const techs = _.orderBy(
      selectedTechnicianIDs.map(id => {
        const tech = technicians.find(tech => tech.userID == id);
        return {id: id.toString(), name: `${tech?.firstName} ${tech?.lastName}`};
      }),
      [obj => (obj.id === '-1' ? 0 : 1), 'name'],
    );
    if (isShowUnassignedServiceJobs) {
      const unassignedTech = [{id: '-1', name: 'Unassigned'}];
      const techList = _.orderBy(unassignedTech.concat(techs), [obj => (obj.id === '-1' ? 0 : 1), 'name']);
      setTechniciansList(techList);
    } else {
      setTechniciansList(techs);
    }
  }, [selectedTechnicianIDs.length, isShowUnassignedServiceJobs]);

  const getBufferHeight = (bufferTime: number) => {
    switch (bufferTime) {
      case 0:
        return 0;
      case 15:
        return 20;
      case 30:
        return 42;
      case 45:
        return 64;
      default:
        return 0;
    }
  };

  useEffect(() => {
    const extendsJobs: ISchedulerData[] = [];

    const jobs: ISchedulerData[] = schedulerData.map(schData => {
      let bufferHeight = 0;
      if (schData.travelTimeBufferInMinutes) {
        const startTime = new Date(moment.utc(schData.startDateTime_utc).local().format('YYYY-MM-DD HH:mm'));
        schData.startDateTime_utc = new Date(
          startTime.setMinutes(startTime.getMinutes() - schData.travelTimeBufferInMinutes),
        ).toISOString();
        const endTime = new Date(moment.utc(schData.endDateTime_utc).local().format('YYYY-MM-DD HH:mm'));
        schData.endDateTime_utc = new Date(
          endTime.setMinutes(endTime.getMinutes() + schData.travelTimeBufferInMinutes),
        ).toISOString();

        bufferHeight = getBufferHeight(schData.travelTimeBufferInMinutes);
      }

      if (schData.technicians.length > 1) {
        for (let i = 1; i < schData.technicians.length; i++) {
          const tech = schData.technicians[i];
          extendsJobs.push({
            bufferHeight,
            customerName: schData.onSiteContactFirstName + ' ' + schData.onSiteContactLastName,
            description: schData.description,
            emailAddress: schData.onSiteContactEmailAddress,
            endDate: moment.utc(schData.endDateTime_utc).local().format('YYYY-MM-DD HH:mm'),
            id: schData.serviceBookingID.toString() + i,
            isSent: schData.isSent,
            isWaitingForStock: schData.isWaitingForStock,
            linkedTransactions: schData.linkedTransactions,
            phoneNumber: schData.onSiteContactPhone,
            quoteId: schData.serviceQuoteID,
            serviceJobID: schData.serviceJobID,
            startDate: moment.utc(schData.startDateTime_utc).local().format('YYYY-MM-DD HH:mm'),
            status: schData.status,
            technician: {
              id: tech.technicianUserID ? `${tech.technicianUserID}` : '-1',
              name: tech.technicianUserID ? `${tech.firstName} ${tech.lastName}` : 'Unassigned',
            },
            title: schData.customerName,
            totalBookedJobHours: schData.totalBookedJobHours,
          });
        }
      }

      return {
        id: schData.serviceBookingID.toString(),
        startDate: moment.utc(schData.startDateTime_utc).local().format('YYYY-MM-DD HH:mm'),
        endDate: moment.utc(schData.endDateTime_utc).local().format('YYYY-MM-DD HH:mm'),
        title: schData.customerName,
        description: schData.description,
        serviceJobID: schData.serviceJobID,
        status: schData.status,
        quoteId: schData.serviceQuoteID,
        bufferHeight,
        technician: {
          id: schData?.technicians[0]?.technicianUserID ? `${schData?.technicians[0]?.technicianUserID}` : '-1',
          name: schData?.technicians[0]?.technicianUserID
            ? `${schData?.technicians[0]?.firstName} ${schData?.technicians[0]?.lastName}`
            : 'Unassigned',
        },
        isWaitingForStock: schData.isWaitingForStock,
        isSent: schData.isSent,
        totalBookedJobHours: schData.totalBookedJobHours,
        emailAddress: schData.onSiteContactEmailAddress,
        phoneNumber: schData.onSiteContactPhone,
        linkedTransactions: schData.linkedTransactions,
        customerName: schData.onSiteContactFirstName + ' ' + schData.onSiteContactLastName,
      } as ISchedulerData;
    });

    // setting jobs and extendsJobs to data
    setData([...jobs, ...extendsJobs]);
  }, [schedulerData]);

  return {
    techniciansList,
    setTechniciansList,
    data,
  };
};

export {useScheduleJob};
