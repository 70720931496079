import React from 'react';

export function IconCall (props: SVGProps) {
    return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={12}
          height={13}
          fill="none"
          {...props}
        >
          <path
            fill="#CCC"
            fillRule="evenodd"
            d="M8.864 12.012C4.8 12.15-2.526 4.885.875.946l.575-.5a1.54 1.54 0 012.163.022.5.5 0 01.043.05l.9 1.17a1.554 1.554 0 01-.005 2.14l-.579.727a6.394 6.394 0 003.466 3.473l.732-.582a1.585 1.585 0 012.14-.003l1.17.9a.557.557 0 01.05.042c.602.606.602 1.583 0 2.19l-.455.524a3.077 3.077 0 01-2.21.913zm-6.345-11a.547.547 0 00-.387.16l-.576.5c-2.823 3.394 5.816 11.552 8.787 8.744l.456-.525a.55.55 0 00.043-.78l-1.164-.893a.406.406 0 01-.05-.044.561.561 0 00-.774 0 .505.505 0 01-.042.038l-.979.78a.5.5 0 01-.49.076 7.505 7.505 0 01-4.41-4.406.5.5 0 01.073-.5l.777-.978a.427.427 0 01.038-.042.55.55 0 000-.774.433.433 0 01-.043-.05l-.892-1.162a.546.546 0 00-.367-.141v-.003z"
            clipRule="evenodd"
          />
        </svg>
      )
}
