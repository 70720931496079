import * as React from 'react';
import {SVGProps} from 'react';

const IconPaidInvoices = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={26} height={26} {...props}>
    <g className="fill-primary">
      <path
        style={{
          stroke: 'none',
          fillRule: 'evenodd',
          fillOpacity: 1,
        }}
        d="M12.387 0a7.531 7.531 0 0 1 5.363 2.223l3.773 3.773a7.515 7.515 0 0 1 2.223 5.363v3.938c0 .598-.484 1.082-1.082 1.082a1.08 1.08 0 0 1-1.082-1.027l-.004-.055.004-3.938c0-.18-.035-.351-.055-.527h-5.363a3.248 3.248 0 0 1-3.25-3.25V2.219c-.176-.016-.348-.051-.527-.051h-4.89a3.25 3.25 0 0 0-3.25 3.25v15.164a3.25 3.25 0 0 0 3.25 3.25h7.538c.582 0 1.055.457 1.082 1.031l.004.055c0 .598-.484 1.082-1.086 1.082H7.496a5.42 5.42 0 0 1-5.414-5.418V5.418A5.42 5.42 0 0 1 7.496 0Zm1.61 18.418c.597 0 1.085.484 1.085 1.082 0 .598-.488 1.082-1.086 1.082H8.582a1.083 1.083 0 1 1 0-2.164Zm3.25-4.336c.597 0 1.085.488 1.085 1.086 0 .598-.488 1.082-1.086 1.082H8.582a1.083 1.083 0 1 1 0-2.168ZM15.081 2.906v4.676c0 .602.484 1.086 1.082 1.086h4.676a5.476 5.476 0 0 0-.848-1.14L16.22 3.753a5.352 5.352 0 0 0-1.137-.848ZM25.801 18.7a1.17 1.17 0 0 1-.29.852l-4.855 5.617c-.172.191-.406.32-.66.363l-.168.032a1.136 1.136 0 0 1-.86-.36l-2.878-3.062a1.18 1.18 0 0 1-.016-1.703c.227-.223.535-.34.852-.32.316.015.613.163.82.41l2.012 2.132 4.031-4.664a1.139 1.139 0 0 1 1.617-.105c.235.207.375.496.395.808Zm0 0"
      />
    </g>
  </svg>
);

export default IconPaidInvoices;
